import { useState } from 'react';
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import s10_1 from '../../../../assets/images/phones_carousel/s10_1.png'
import s10_2 from '../../../../assets/images/phones_carousel/s10_2.png'
import s10_3 from '../../../../assets/images/phones_carousel/s10_3.png'
import '../../../../assets/common.css'
function ReactSimplyCarouselExample1() {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 22,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 22,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
            partialVisibilityGutter: 22,
        }
    };



    return (
        <div className='app-slider'>
            <div className="w-full max-w-[1315px] mx-auto px-2.5 pt-12 bg-white">
                <div className="w-full mb-5 font-poppins">
                    <div className="flex justify-center items-center">
                        <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 md:block md:w-60 sm: hidden h-[414px] sm:w-100 ">
                            {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                            <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                <img className="w-full h-full" src={s10_1} alt="Screen Shot" />
                            </div>
                        </div>
                        <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 md:block md:w-60 sm: hidden h-[414px] sm:w-100 ">
                            {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                            <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                <img className="w-full h-full" src={s10_2} alt="Screen Shot" />
                            </div>
                        </div>
                        <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 md:block md:w-60 sm: hidden h-[414px] sm:w-100 ">
                            {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                            <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                <img className="w-full h-full" src={s10_3} alt="Screen Shot" />
                            </div>
                        </div>
                        {/* Responsive */}
                        <div className='block md:hidden sm:block mt-4 w-full'>
                            <Carousel
                                partialVisible={false} responsive={responsive}
                                swipeable={true}
                                draggable={true}
                                showDots={true}
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={1000}
                                keyBoardControl={true}
                                customTransition="all .5"
                                transitionDuration={500}
                                containerClass="slider-paddingHowDoesTaboContainer"
                                removeArrowOnDeviceType={["tablet", "mobile"]}

                                dotListClass="custom-dot-list-style"
                                itemClass="slider-paddingApp h-[340px]"

                            >
                                <div className='flex justify-center content-start w-100'>
                                    <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 sm:block w-[190px] h-[360px]">
                                        {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                                        <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                            <img className="w-full h-full" src={s10_1} alt="Screen Shot" />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center content-start w-100'>
                                    <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 sm:block w-[190px] h-[360px]" >
                                        {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                                        <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                            <img className="w-full h-full" src={s10_2} alt="Screen Shot" />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex justify-center content-start w-100'>
                                    <div className="relative xl:w-60 xl:h-[490px] xl:mr-7 md:mr-2.5 sm:block w-[190px] h-[360px]">
                                        {/* <img className="w-full h-full" src={S20} alt="Mobile" /> */}
                                        <div className="absolute top-0 w-full h-full px-2.5 py-3">
                                            <img className="w-full h-full" src={s10_3} alt="Screen Shot" />
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReactSimplyCarouselExample1;