import React, {
  Fragment,
  useState,
  useEffect,
} from "react";
import moment from "moment";
import QRCode from "react-qr-code";
import TimeAgo from "javascript-time-ago";
import Lightbox from "react-image-lightbox";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactFlagsSelect from "react-flags-select";
import en from "javascript-time-ago/locale/en.json";
// import LocalizedStrings from 'react-localization';
import { Carousel } from "react-responsive-carousel";
import ReactStars from "react-rating-stars-component";
import { CirclesWithBar } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";

//import Rating from "../../../components/Rating"
import Modal from "../common/Modal/Modal";
import MapComponent from "./GoogleMap";

import httpService from "../../services/http";

import QRCodeLogo from "../../assets/images/qr_code_logo.png";
import Copy from "../../assets/images/copy.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "react-toastify/dist/ReactToastify.css";
import "react-image-lightbox/style.css";
import "../../assets/reservation.css";

TimeAgo.addLocale(en);

const Reservation = () => {
  const { id } = useParams();
  // const { language, addLanguage } = useContext(LanguageContext);
  const [appRating, setAppRating] = useState(0);
  const [foodRating, setFoodRating] = useState(0);
  const [serviceRating, setServiceRating] = useState(0);
  const [ambienceRating, setAmbienceRating] = useState(0);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const [reservationData, setReservationData] = useState();
  const [otp, setOtp] = useState("");
  const [apiLoading, setApiLoading] = useState(false);
  //const [showLanguage, setShowLanguage] = useState(false);
  const [lang, setLang] = useState("");
  const timeAgo = new TimeAgo("en-US");
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState("");
  const [photoIndex, setPhotoIndex] = useState(0);

  // Modal open state
  const [termModal, setTermModal] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [warningModal, setWarningModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showImageLightBox, setShowImageLightBox] = useState(false);
  const [releaseReservationModal, setReleaseReservationModal] = useState(false);
  // Toggle for Modal
  const toggleTerms = () => setTermModal(!termModal);

  const releaseReservationToggle = () =>
    setReleaseReservationModal(!releaseReservationModal);
  const warningToggle = () => setWarningModal(!warningModal);


  // useEffect(() => {
  //   if (typeof window !== "undefined") {
  //     const language = localStorage.getItem("language");

  //     if (language && language !== "English") {
  //       addLanguage("Romanian");
  //       i18n.changeLanguage("ro")
  //     }
  //   }
  // }, [])

  useEffect(() => {
    if (id) {
      getReservationByKey();
    }
    const getLang = localStorage.getItem("language");
    if (getLang === "Romanian") {
      i18n.changeLanguage("ro")
      setLang("ro");
      setTimeout(setSelected("RO"), 1000);

    } else if (getLang === "English") {
      i18n.changeLanguage("en")
      setLang("en");
      // setSelected("GB")
      setTimeout(setSelected("GB"), 1000);
    }

  }, [id]);

  const getReservationByKey = async () => {
    try {
      const response = await httpService.get(`/booking/reservation_by_unique_id/${id}`);
      const { data = {} } = response;
      if (data) {
        setReservationData(response.data);
        warningToggle();
      } else {
        toast("No reservation found", { type: "error" })
      }
    } catch (error) {
      if (error?.message) {
        toast(error?.message, { type: "error" })
      }
    }
  };
  const updateReview = (event) => {
    if (!appRating) {
      const notify = () =>
        toast("Please select valid rating", { type: "error" });
      notify();
      return false;
    }
    setLoading(true);
    httpService
      .post("/create-business-review", {
        comment: comment,
        rating: appRating,
        food_rating: foodRating,
        service_rating: serviceRating,
        ambience_rating: ambienceRating,
        uniq_id: id,
      })
      .then((response) => {
        setLoading(false);
        const notify = () =>
          toast("Thank you for your review", { type: "success" });
        notify();
      })
      .catch((e) => {
        const notify = () =>
          toast("Invalid Reservation Token", { type: "error" });
        notify();
        setLoading(false);
      });
  };

  const getOtp = () => {
    setApiLoading(true);
    httpService
      .post(
        "/booking/verifyBookingByOtp/" +
        reservationData.id,
        {
          type: reservationData.business.type,
        }
      )
      .then((response) => {
        setApiLoading(false);
      })
      .catch((e) => {
        const notify = () => toast(e.response.data.message, { type: "error" });
        notify();
        setApiLoading(false);
      });
  };

  const submitOtp = (event) => {
    if (!otp) {
      const notify = () => toast("Please enter valid opt", { type: "error" });
      notify();
      return false;
    }
    setApiLoading(true);
    httpService
      .post(
        "/booking/verifyPinAndUpdateBooking/" +
        reservationData.id,
        {
          type: reservationData.business.type,
          pin: otp,
        }
      )
      .then((response) => {
        setApiLoading(false);
        const notify = () =>
          toast("Reservation updated successfully", { type: "success" });
        notify();
        getReservationByKey();
        releaseReservationToggle();
      })
      .catch((e) => {
        const notify = () => toast(e.response.data.message, { type: "error" });
        notify();
        setApiLoading(false);
      });
  };

  const onSetLanguage = (lang) => {
    // RO", "GB
    if (lang === "RO") {
      // addLanguage("Romanian")
      localStorage.setItem("language", "Romanian")
      //strings.setLanguage('ro');
      i18n.changeLanguage("ro")
      setLang("ro");
    } else {
      // addLanguage("English")
      localStorage.setItem("language", "English")
      //strings.setLanguage('en');
      i18n.changeLanguage("en")
      setLang("en");
    }
  }

  const openOtDialog = () => {
    getOtp();
    releaseReservationToggle();
  };

  const copyText = (text) => {
    if (text && navigator && navigator.clipboard) {
      navigator?.clipboard.writeText(text);
      toast(t("text_copy"), { type: "info" });
    }
  };

  const openURL = (url) => {
    const openUrl = !url.includes("http") ? `https://${url}` : url;
    const newWindow = window.open(openUrl, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null;
  };

  let startAt = "NA", endAt = "NA";
  if (reservationData && reservationData?.arrive_time) {
    startAt = reservationData.arrive_time;
    let endTime = moment(reservationData.arrive_time, "HH:mm");
    endTime.add(reservationData?.time_occupied ?? 0, "minutes");
    endAt = endTime.format("HH:mm");
  }
  const totalReview = reservationData && reservationData?.business?.reviews ? [...reservationData?.business?.reviews].filter(r => r.rate > 0) : [];
  const firstFiveReviews = [...totalReview].slice(0, 5);
  const images = reservationData && reservationData?.business?.gallery ? reservationData?.business?.gallery.map(g => g.url) : [];
  return (
    <Fragment>
      <div className="sm:flex hidden items-center justify-center min-h-screen p-10">
        <h1 className="text-[#6744F9] text-3xl text-center">Not allow to be opened in desktop. Only for mobile phones</h1>
      </div>
      <div className="sm:hidden block">
        <ToastContainer />
        {reservationData ? (
          <div className="reservation-review font-poppins">
            {/* <h1 className="head-title"><img src="/logoStart.png" /></h1> */}
            <div className="flex items-center justify-between ">
              <div className="text-black text-[13px]">{t("power_by")} <span onClick={() => openURL("https://tabo.app")} className="text-[#6744F9] underline underline-offset-2">Tabo</span></div>
              <ReactFlagsSelect
                className="max-w-[50px] select-language"
                countries={["RO", "GB"]}
                placeholder={t("select_language")}
                customLabels={{ RO: "Romanian", GB: "English" }}
                selected={selected}
                showSelectedLabel={false}
                onSelect={(code) => {
                  onSetLanguage(code)
                  setSelected(code)
                }}
              />
            </div>
            {reservationData != null && reservationData.business && (
              <Carousel className="mt-5 rounded-[20px] overflow-hidden drop-shadow-xl" showThumbs={false}>
                {reservationData.business.gallery.map((data, i) => {
                  return (
                    <div
                      key={data.url}
                      onClick={() => {
                        setPhotoIndex(i);
                        setShowImageLightBox(true);
                      }}
                    >
                      <img className="object-cover h-48 w-96" src={data.url} />
                    </div>
                  );
                })}
              </Carousel>
            )}
            <div className="location-name rev-section">
              <div className="d-flex space-between break-word">
                <h4 className="font-medium text-lg">{reservationData.business.location_name}</h4>
                <button className="ml-2 status-name break-normal" disabled>
                  {t(reservationData.reservation_status)}
                </button>
              </div>
            </div>
            <div className="reservation-details rev-section">
              <h4 className="mb-1 text-lg text-center font-medium text-[#6744F9]">{t("reservation_details")}</h4>
              <h5 className="mb-10 text-center text-sm">ID: {id}</h5>
              <div className="py-3 mx-2 bg-white shadow-lg shadow-gray-500 rounded-lg break-word text-sm pl-3 pr-3 relative">
                <div className="flex items-start mb-10">
                  <h5 className="w-[30%]">{t("time")}</h5>
                  <h5 className="ml-1">: {moment(`${reservationData.arrive_time} ${reservationData.selected_day}`, "HH:mm YYYY-MM-DD").format("hh:mm DD/MMM/YYYY")}</h5>
                </div>
                <div className="flex items-start mb-10">
                  <h5 className="w-[30%]">{t("guests")}</h5>
                  <h5 className="ml-1">: {reservationData.number_persons}</h5>
                </div>
                <div className="flex items-start mb-10">
                  <h5 className="w-[30%]">{t("zone")}</h5>
                  <h5 className="ml-1">: {reservationData?.zone?.name ?? "NA"}</h5>
                </div>
                <div className="flex items-start mb-10">
                  <h5 className="w-[30%]">{t("start_at")}</h5>
                  <h5 className="ml-1">: {startAt}</h5>
                </div>
                <div className="flex items-start">
                  <h5 className="w-[30%]">{t("end_at")}</h5>
                  <h5 className="ml-1">: {endAt}</h5>
                </div>
                {
                  reservationData.qr_code ? (
                    <button className="hover:bg-transparent absolute right-3 bottom-3" onClick={() => setShowQRModal(true)}>
                      <img className="h-9" src={QRCodeLogo} />
                    </button>
                  ) : null
                }
              </div>
            </div>

            <div className="google-map rev-section">
              <h4 className="mb-10 text-lg text-center font-medium text-[#6744F9]">{t("find_us")}</h4>
              <MapComponent
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBAQpW7AAOUhRjIX3U9WaXkSAHfXHSZvhE&v=3.exp&libraries=geometry,drawing,places"
                lat={reservationData.business.settings.latitude}
                lng={reservationData.business.settings.longitude}
                loadingElement={<div className="h-full" />}
                containerElement={
                  <div className="mb-20 ring-2 h-[400px]" />
                }
                mapElement={<div className="h-full" />}
              ></MapComponent>
              <h4 className="mb-10 mt-[20px]">{t("address")}</h4>
              <div className="mb-20 flex items-start justify-between">
                <h5>
                  {reservationData.business.settings.address}
                  <br />
                  <p className="text-lg">{reservationData.business.settings.beach_location_city}, {reservationData.business.settings.beach_location_country}</p>
                </h5>
                <button
                  onClick={() => copyText(reservationData.business.settings.address)}
                  className="p-1 bg-white hover:bg-white ring-[#6744F9] ring-1 rounded ml-1 ease-linear transition-all duration-150"
                >
                  <img src={Copy} />
                </button>
              </div>
              <h4 className="mb-10">{t("contact_info")}:</h4>
              <h5 className="mb-10">
                {t("phone")} : {reservationData.business.settings.contact_phone}
              </h5>
              <h5>
                {t("website")} :{" "}
                <span
                  onClick={() => openURL(reservationData.business.settings.website)}
                  className="text-[#6744F9] p-0"
                  href={reservationData.business.settings.website}
                >
                  {reservationData.business.settings.website}
                </span>
              </h5>
            </div>

            {reservationData.business.business_menu && (
              <div onClick={() => openURL(reservationData?.business?.business_menu?.url)} className="facilities rev-section">
                <h4 className="mb-0 text-[#6744F9] visited:text-[#6744F9] text-lg text-center ">{t("menu")}</h4>
                <h5 className="underline underline-offset-4 text-xs text-center text-black visited:text-black">
                  ({t("our_menu")})
                </h5>
              </div>
            )}
            {reservationData.business.settings.cuisine_json && (
              <div className="reviews rev-section">
                <h4 className="mb-10 text-lg text-[#6744F9] text-center">{t("cuisine")}</h4>
                {reservationData.business.settings.cuisine_json.map((cuisine) => {
                  return (
                    <button className="facility text-sm" disabled key={cuisine.id}>
                      {cuisine.name}
                    </button>
                  );
                })}
              </div>
            )}

            <div className="facilities rev-section">
              <h4 className="mb-10 text-lg text-[#6744F9] text-center">{t("facilities")}</h4>
              {reservationData.business.facilities &&
                reservationData.business.facilities.map((facility) => {
                  return (
                    <button className="facility text-sm" disabled key={facility.id}>
                      {t(facility.facility.name)}
                    </button>
                  );
                })}
            </div>

            <div className="facilities rev-section">
              <h4 className="mb-10 text-lg text-[#6744F9] text-center">{t("terms_condition")}</h4>
              <h5 onClick={toggleTerms} className="underline underline-offset-4 text-xs text-center text-black visited:text-black">
                {t("check_out")}{" "}{t("terms_condition")}
              </h5>
            </div>
            {totalReview.length > 0 && (
              <div className="reviews rev-section mb-10">
                <h4 className="text-lg text-[#6744F9] text-center mb-2">{t("reviews")} ({totalReview.length})</h4>
                {/* <div className="d-flex">
                <div className="d-flex">
                  <span className="avg-rating">
                    {reservationData.business.avg_rate}{" "}
                  </span>
                  <span className="start-rating">★</span>
                </div>
                <span className="total-rating">
                  {t("total_review")} : {reservationData.business.reviews.length}{" "}
                </span>
              </div> */}
                <div className="reviews-wrapper hide-scrollbar">
                  {firstFiveReviews.map((review) => {
                    return (
                      <div key={review.id} onClick={() => setShowReviewModal(true)} className="review mb-2 ml-1 bg-white shadow-md shadow-gray-500 font-poppins">
                        <div className="flex items-center mb-2">
                          <div>
                            {review.customer && review.customer.photo ? (
                              <div className="profile">
                                <img src={review.customer.photo}></img>
                              </div>
                            ) : (
                              <div className="no-profile"></div>
                            )}
                          </div>
                          <div className="flex items-center w-full">
                            <div className="flex-1">
                              <div className="flex justify-end">
                                <span className="time">
                                  {timeAgo.format(new Date(review.created_at))}
                                </span>
                              </div>
                              {review.customer && review.customer && (
                                <span className="text-xs">
                                  {review.customer.first_name}{" "}
                                  {review.customer.last_name}
                                </span>
                              )}
                              {/* <div className="d-flex">
                                <ReactStars
                                  count={5}
                                  size={13}
                                  value={review.rate}
                                  activeColor="#ffd700"
                                  classNames="review-star"
                                />
                                <span className="text-xs"> ({review.rate})</span>
                              </div>
                              {review.review} */}
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <h5 className="text-xs">{t("ambiance")}: </h5>
                          <div className="d-flex ml-2">
                            <ReactStars
                              count={5}
                              size={13}
                              value={review?.ambience_rating}
                              activeColor="#ffd700"
                              classNames="review-star"
                            />
                            <span className="text-xs"> ({review?.ambience_rating})</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <h5 className="text-xs">{t("food")}: </h5>
                          <div className="d-flex ml-2">
                            <ReactStars
                              count={5}
                              size={13}
                              value={review?.food_rating}
                              activeColor="#ffd700"
                              classNames="review-star"
                            />
                            <span className="text-xs ml-1"> ({review?.food_rating})</span>
                          </div>
                        </div>
                        <div className="d-flex">
                          <h5 className="text-xs">{t("service")}: </h5>
                          <div className="d-flex ml-2">
                            <ReactStars
                              count={5}
                              size={13}
                              value={review?.service_rating}
                              activeColor="#ffd700"
                              classNames="review-star"
                            />
                            <span className="text-xs"> ({review?.service_rating})</span>
                          </div>
                        </div>
                        <div className="text-xs italic text-ellipsis max-w-full clear-both inline-block overflow-hidden whitespace-nowrap">
                          {review?.review ?? ""}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {reservationData.reservation_status === "completed" ||
              reservationData.reservation_status === "released" ||
              reservationData.reservation_status === "expired" ||
              reservationData.reservation_status ===
              "canceled" ? null : reservationData.reservation_status ===
                "occupied" ? (
              <button type="button" className="btn-submit" onClick={openOtDialog}>
                {t("release")}
              </button>
            ) : (
              <button type="button" className="btn-submit" onClick={openOtDialog}>
                {t("cancel")}
              </button>
            )}
          </div>
        ) : (
          <div className="page-loader">
            <CirclesWithBar
              type="Puff"
              color="#00BFFF"
              height={70}
              width={70}
              timeout={300000} //3 secs
            />
          </div>
        )}
        <Modal
          visible={termModal}
          toggle={toggleTerms}
          headerTitle={t("terms_condition")}
          renderFooter={() => (
            <Fragment>
              <button
                type="button"
                onClick={toggleTerms}
                className="bg-[#6744F9] text-white active:bg-[#6744F9] font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                {t("okay")}
              </button>
            </Fragment>
          )}
        >
          {reservationData && reservationData.business.rules
            ? JSON.parse(reservationData.business.rules.rules).en
            : ""}
        </Modal>

        <Modal
          headerTitle={t("submit_otp")}
          visible={releaseReservationModal}
          toggle={releaseReservationToggle}
          renderFooter={() => (
            <Fragment>
              <button
                disabled={apiLoading}
                onClick={releaseReservationToggle}
                className="bg-red-500 text-white active:bg-red-500 font-medium uppercase text-xs p-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                {t("cancel")}
              </button>
              <button
                onClick={getOtp}
                disabled={apiLoading}
                className="bg-yellow-500 text-white active:bg-yellow-500 font-medium uppercase text-xs p-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                {t("resendOtp")}
              </button>
              <button
                onClick={submitOtp}
                disabled={apiLoading}
                className="bg-[#6744F9] text-white active:bg-[#6744F9] font-medium uppercase text-xs p-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
              >
                {apiLoading ? (
                  <CirclesWithBar
                    color="#00BFFF"
                    height={30}
                    width={30}
                    timeout={30000} //3 secs
                  />
                ) : (
                  t("submit")
                )}
              </button>
            </Fragment>
          )}
        >
          <input
            required
            type="text"
            id="otp_input"
            placeholder={t("otp_placeholder")}
            onChange={(e) => {
              setOtp(e.target.value);
            }}
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-slate-50 dark:border-gray-600 dark:placeholder-gray-400 dark:text-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </Modal>

        <Modal
          visible={warningModal}
          toggle={warningToggle}
          renderFooter={() => (
            <Fragment>
              <button
                type="button"
                onClick={warningToggle}
                className="bg-[#6744F9] text-white active:bg-[#6744F9] font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                {t("close")}
              </button>
            </Fragment>
          )}
        >
          <p>{t("warningAlert")}</p>
        </Modal>
        <Modal
          titleCenter
          renderFooter={null}
          visible={showReviewModal}
          containerClass="w-[89vw] pb-3"
          modalContainerClass="max-w-[90vw]"
          titleTextClass="text-[1rem] text-[#6744F9]"
          toggle={() => setShowReviewModal(false)}
          bodyClass="px-3 pt-2 overflow-x-hidden max-h-[90vh]"
          headerTitle={`${t("reviews")} (${totalReview.length})`}
        >
          {
            totalReview.map((review) => (
              <div className="review-card mb-3 last:mb-0 bg-white shadow-sm shadow-gray-400 font-poppins">
                <div className="flex items-start">
                  <div>
                    {review.customer && review.customer.photo ? (
                      <div className="profile">
                        <img src={review.customer.photo}></img>
                      </div>
                    ) : (
                      <div className="no-profile"></div>
                    )}
                  </div>
                  <div className="flex items-start w-full">
                    <div className="flex flex-col w-full">
                      {review.customer && review.customer && (
                        <span className="text-xs">
                          {review.customer.first_name}{" "}
                          {review.customer.last_name}
                        </span>
                      )}
                      <span className="time mt-1">
                        {timeAgo.format(new Date(review.created_at))}
                      </span>
                    </div>
                    <div className="d-flex">
                      <span className="mr-1">
                        {review.rate}
                      </span>
                      <span className="start-rating">★</span>
                    </div>
                  </div>
                </div>
                <div className="flex items-start flex-nowrap overflow-x-scroll pb-1 pt-3 hide-scrollbar">
                  <div className="min-w-max border border-solid border-gray-300 rounded-lg py-1 px-2 text-xs mr-2">
                    <span className="start-rating">{review?.ambience_rating ?? 0}★</span>  {t("ambiance")}
                  </div>
                  <div className="min-w-max border border-solid border-gray-300 rounded-lg p-1 text-xs mr-2">
                    <span className="start-rating">{review?.food_rating ?? 0}★</span>  {t("food")}
                  </div>
                  <div className="min-w-max border border-solid border-gray-300 rounded-lg p-1 text-xs">
                    <span className="start-rating">{review?.service_rating ?? 0}★</span>  {t("service")}
                  </div>
                </div>
                {review?.review ? <div className="mt-1 text-xs italic">
                  {review?.review ?? ""}
                </div> : null}
              </div>
            ))
          }
        </Modal>
        <Modal
          titleCenter
          renderFooter={null}
          visible={showQRModal}
          headerTitle={t("qr_code")}
          containerClass="w-[89vw] pb-3"
          modalContainerClass="max-w-[90vw]"
          toggle={() => setShowQRModal(false)}
          titleTextClass="text-[1rem] text-[#6744F9]"
          bodyClass="px-3 pt-2 pb-0 overflow-x-hidden max-h-[90vh]"
        >
          {reservationData?.qr_code ? (
            <div className="qr-code rev-section border-b-none">
              <QRCode size={150} viewBox={`0 0 150 150`} className="mx-auto" value={reservationData.qr_code} />
              <h5 className="mt-4 text-[14px] text-red-700">
                {t("scan_text_warn")}
              </h5>
            </div>
          ) : null}
        </Modal>
        {
          showImageLightBox ? (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
              onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
              onCloseRequest={() => {
                setShowImageLightBox(false);
                setPhotoIndex(0);
              }}
            />
          ) : null
        }
      </div>
    </Fragment>
  );
};

export default Reservation;
