import React from "react";
import Logo from '../../../assets/images/logo.png';
import { Link } from 'react-scroll';
import { useTranslation } from "react-i18next";

const MobNavigation = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="fixed top-0 left-0 w-full h-full bg-backdropColor backDrop" onClick={props.onClose}></div>
      <div className="justify-between items-center pt-12 block w-64 px-5 fixed top-0 left-0 h-screen z-10 bg-white sideBar">
        <div className="flex justify-between">
          <div className="w-16 h-14 pr-1 mb-5">
            <img className="w-full" src={Logo} alt="Logo" />
          </div>
          <span className="text-3xl -m-2.5 h-8" onClick={props.onClose}>
            &times;
          </span>
        </div>
        <ul className="mb-5 text-lg font-normal leading-7 text-black font-poppins capitalize">
          <li className="mb-4 font-medium text-base">
            <Link activeClass="active" to="#">{t('topHeader.home')}</Link>
          </li>
          <li className="mb-4 font-medium text-base">
            <Link to="what-is-tabo" spy={true} smooth={true}>{t('topHeader.about')}</Link>
          </li>
          <li className="mb-4 font-medium text-base">
            <Link to="trusted-by" spy={true} smooth={true}>{t('topHeader.trustedBy')}</Link>
          </li>
          <li className="mb-4 font-medium text-base">
            <Link to="pricing-plan" spy={true} smooth={true}>{t('topHeader.pricing')}</Link>
          </li>
          <li className="mb-4 font-medium text-base">
            <Link to="contact-us" spy={true} smooth={true}>{t('topHeader.contactUs')}</Link>
          </li>
        </ul>
        <div className="flex items-center font-poppins flex-wrap gap-5">
          <button className="text-base font-normal leading-7 text-white py-1.5 px-4 rounded-[10px] bg-primary">{t('header.signUp')}</button>
        </div>
      </div>
    </div>
  )
}

export default MobNavigation;