import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./Slider.css"
import BeachMain from '../../../../assets/images/3D_Carousel/beach-main.png'
import BeachReservation from '../../../../assets/images/3D_Carousel/beach-reservation.png'
import BeachReservation2 from '../../../../assets/images/3D_Carousel/beach-reservation2.png'
import RestaurantMain from '../../../../assets/images/3D_Carousel/restaurant-main2.png'
import RestaurantReservation from '../../../../assets/images/3D_Carousel/restaurant-comingup.png'
import RestaurantReservation2 from '../../../../assets/images/3D_Carousel/restaurant-reservation.png'
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import '../../../../assets/common.css'
import { useTranslation, Trans } from "react-i18next";


function ReactSimplyCarouselExample() {
  const [slide1, setSlide1] = useState(false)
  const [slide2, setSlide2] = useState(true)
  const [slide3, setSlide3] = useState(false)
  const [slide4, setSlide4] = useState(false)
  const [slide5, setSlide5] = useState(true)
  const [slide6, setSlide6] = useState(false)

  const [activeSlide1, setActiveSlide1] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const { t, i18n } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 30,
    }
  };



  return (
    <div className='app-slider'>
      <Tabs className="w-full react-tabs-ul">
        <TabList className="flex justify-center mb-4 items-center">
          <Tab className="border text-center flex items-center justify-center w-[152px] h-[37px] py-2.5 font-medium text-[10px] sm:text-xs text-[#15143966] customFirstRounded leading-[0px]">{t("OurApp.appSlider.tab2")}</Tab>
          <Tab className="border text-center first-tab flex items-center justify-center py-2.5 font-medium text-[10px] sm:text-xs text-[#15143966] w-[152px] h-[37px] customLastRounded leading-[0px]">{t("OurApp.appSlider.tab1")}</Tab>
        </TabList>
        <TabPanel>
          <div className="md:block  md: max-w-7xl mx-auto px-5 md:pt-24 bg-white sm: hidden">
            <div className="w-full">
              <section id="slider2">
                <input type={"radio"} name="slider" id="s4" defaultChecked={slide4} value={slide4} onClick={() => {
                  setSlide4(true)
                  setSlide5(false)
                  setSlide6(false)
                }} />
                <input type={"radio"} name="slider" id="s5" defaultChecked={slide5} value={slide5} onClick={() => {
                  setSlide4(false)
                  setSlide5(true)
                  setSlide6(false)
                }} />
                <input type={"radio"} name="slider" id="s6" defaultChecked={slide6} value={slide6} onClick={() => {
                  setSlide4(false)
                  setSlide5(false)
                  setSlide6(true)
                }} />
                <div className='cards2'>
                  <label htmlFor="s4" id="slide4">
                    <img src={RestaurantReservation} height="100%" alt="RestaurantReservation"/>
                  </label>
                  <label htmlFor="s5" id="slide5">
                    <img src={RestaurantMain} height="100%" alt="RestaurantReservation"/>
                  </label>
                  <label htmlFor="s6" id="slide6">
                    <img src={RestaurantReservation2} height="100%" alt="RestaurantReservation"/>
                  </label>
                </div>

              </section>
            </div>
          </div>
          {/* {Responsive} */}
          <div className="sm: block h-full sm: w-full max-w-7xl mx-auto px-5 md:pt-24 bg-white md:hidden sm:pb-0 pt-2 pr-0 pl-0">
            <div className='services-carousel why-tabo-new service-top my-services mb-8 md:hidden sm:block mt-4'>
              <Carousel partialVisible={false} responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                itemClass="slider-padding"
                dotListClass="custom-dot-list-style"
                containerClass="carousel-container pl-[15px]"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                beforeChange={(nextSlide) => setActiveSlide1(nextSlide)}
              >
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={RestaurantMain} alt="RestaurantReservation"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={RestaurantReservation} alt="RestaurantReservation"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={RestaurantReservation2} alt="RestaurantReservation"/>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="md:block md: w-full max-w-7xl mx-auto px-5 md:pt-24 bg-white sm: hidden">
            <div className="w-full">
              <section id="slider">
                <input type={"radio"} name="slider" id="s1" defaultChecked={slide1} value={slide1} onClick={() => {
                  setSlide2(false)
                  setSlide1(true)
                  setSlide3(false)
                }} />
                <input type={"radio"} name="slider" id="s2" defaultChecked={slide2} value={slide2} onClick={() => {
                  setSlide2(true)
                  setSlide1(false)
                  setSlide3(false)
                }} />
                <input type={"radio"} name="slider" id="s3" defaultChecked={slide3} value={slide3} onClick={() => {
                  setSlide2(false)
                  setSlide1(false)
                  setSlide3(true)
                }} />
                <div className='cards'>
                  <label htmlFor="s1" id="slide1">
                    <img src={BeachReservation} height="100%" alt="BeachReservation"/>
                  </label>
                  <label htmlFor="s2" id="slide2">
                    <img src={BeachMain} height="100%" alt="BeachReservation"/>
                  </label>
                  <label htmlFor="s3" id="slide3">
                    <img src={BeachReservation2} height="100%" alt="BeachReservation"/>
                  </label>
                </div>

              </section>
            </div>
          </div>
          {/* {Responsive} */}
          <div className="block md:hidden pb-8 h-full w-full max-w-7xl mx-auto px-5 md:pt-24 bg-white sm:pb-0 pt-6 pr-0 pl-0">
            <div className='services-carousel why-tabo-new service-top my-services mb-8 md:hidden sm:block'>
              <Carousel partialVisible={false} responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={true}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                sliderClass={"left-[10px]"}
                dotListClass="custom-dot-list-style"
                itemClass="slider-padding pl-15 pr-15"
                containerClass="carousel-container pl-[5px]"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                beforeChange={(nextSlide) => setActiveSlide2(nextSlide)}
              >
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachMain} alt="BeachReservation"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachReservation} alt="BeachReservation"/>
                  </div>
                </div>
                <div className="app-slider-item4">
                  <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-100 md:ml-0 mx-auto md:h-[492px] w-100">
                    <img className="w-full h-full" src={BeachReservation2} alt="BeachReservation"/>
                  </div>
                </div>
              </Carousel>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
}
export default ReactSimplyCarouselExample;