import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom'
import { useTranslation } from "react-i18next";

import MobNavigation from "./mobNavigation";

import UnitedKingdom from '../../../assets/images/united_kingdom.png';
import Triangle from '../../../assets/images/top-triangle.png';
import NavIcon from '../../../assets/images/menu-btn.png';
import Romania from '../../../assets/images/romania.png';
import Logo from '../../../assets/images/logo.png';

import '../../../assets/common.css';

const Header = () => {
  const { t, i18n } = useTranslation();
  const [showLanguageDropDown, setShowLanguageDropDown] = useState(false);
  const wrapperRef = useRef(null)
  const mobileRef = useRef(null)

  useEffect(() => {
    if (document.body.clientWidth > 1000) {
      const close = e => {
        if (e.path[0] !== wrapperRef.current) {
          setShowLanguageDropDown(false)

        }
      }
      document.body.addEventListener("click", close)
      return () => {
        document.body.removeEventListener("click", close)
      }
    }
  }, [document.body.clientWidth])
  useEffect(() => {
    if (document.body.clientWidth < 1000) {
      const close = e => {
        if (!mobileRef.current.contains(e.target)) {
          setShowLanguageDropDown(false)

        }
      }

      document.body.addEventListener("mouseup", close)
      return () => {
        document.body.removeEventListener("mouseup", close)
      }
    }

  }, [document.body.clientWidth])




  useEffect(() => {
    function checkLang() {
      if (window.location.pathname === "/ro") {
        i18n.changeLanguage("ro")

      } else if (window.location.pathname === "/en") {
        i18n.changeLanguage("en")

      }
    }
    setTimeout(checkLang, 50);

  }, []);//window.location.pathname




  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    setShowLanguageDropDown(false);

    if (lng === "en") {
      window.location = '/en';
    } else if (lng === "ro") {
      window.location = '/ro';
    }

  };

  useEffect(() => {

    const getLang = localStorage.getItem("language");
    if (getLang === "Romanian") {
      i18n.changeLanguage("ro")
      //   setLang("ro");
      setTimeout(setSelected("RO"), 1000);

    } else if (getLang === "English") {
      i18n.changeLanguage("en")
      //   setLang("en");
      // setSelected("GB")
      setTimeout(setSelected("GB"), 1000);

    }

  }, []);

  const onSetLanguage = (lang) => {
    // RO", "GB
    if (lang === "RO") {
      // addLanguage("Romanian")
      localStorage.setItem("language", "Romanian")
      //strings.setLanguage('ro');
      i18n.changeLanguage("ro")
      // setLang("ro");
    } else {
      // addLanguage("English")
      localStorage.setItem("language", "English")
      //strings.setLanguage('en');
      i18n.changeLanguage("en")
      // setLang("en");
    }
  }

  const [block, setBlock] = useState(false)
  const blockHandler = () => {
    setBlock(true)
  }

  const [selected, setSelected] = useState("en");

  const blockclass = block ? 'left-mid' : 'left-full'
  const backHandler = () => {
    setBlock(false)
  }


  return (
    <>
      <div className="max-w-[1190px] mx-auto px-2.5 bg-white">
        <div className="lg:flex justify-between items-center pt-2 hidden">
          <div className="w-16 h-14 pr-1">
            <img className="w-full" src={Logo} alt="Logo" />
          </div>
          <ul className="flex items-center lg:pl-20 mb-0 text-lg font-normal leading-7 text-black font-poppins capitalize">
            {/* <li className="mr-10">
                            <Link activeClass="active" to ="#" className="font-medium"><Trans i18nKey="header.home">trans</Trans></Link>
                        </li>
                        <li className="mr-10">
                            <Link to="what-is-tabo" spy={true} smooth={true}><Trans i18nKey="header.about">trans</Trans></Link>
                        </li>
                        <li className="mr-10">
                            <Link to="pricing-plan" spy={true} smooth={true}><Trans i18nKey="header.pricing">trans</Trans></Link>
                        </li>
                        <li>
                            <Link to="contact-us" spy={true} smooth={true}><Trans i18nKey="header.contactUs">trans</Trans></Link>
                        </li> */}
            <li className="mr-6">
              <Link activeClass="active" to="#" className="font-medium">{t('topHeader.home')}</Link>
            </li>
            <li className="mr-6">
              <Link to="what-is-tabo" spy={true} smooth={true}>{t('topHeader.about')}</Link>
            </li>
            <li className="mr-6">
              <Link to="trusted-by" spy={true} smooth={true}>{t('topHeader.trustedBy')}</Link>
            </li>
            <li className="mr-6">
              <Link to="pricing-plan" spy={true} smooth={true}>{t('topHeader.pricing')}</Link>
            </li>
            <li className="mr-6">
              <Link to="contact-us" spy={true} smooth={true}>{t('topHeader.contactUs')}</Link>
            </li>
          </ul>
          <div className="flex items-center font-poppins">
            <a href="https://tabo.io/register" target="_blank" rel="noopener noreferrer">
              <button className="text-lg font-normal leading-7 text-white py-1 px-4 rounded-[10px] bg-primary">
                {t('header.signUp')}
              </button>
            </a>
            {/* <button className="bg-primary rounded p-1 ml-2 text-white" style={{ marginRight: 10}} onClick={() => changeLanguage("en")}>en</button>
                        <button className="bg-primary rounded p-1 mr-2 text-white" onClick={() => changeLanguage("ro")}>ro</button> */}

            <div className="ml-5">
              <div>
                <img ref={wrapperRef} src={i18n.language === "ro" ? Romania : UnitedKingdom} className="w-8 h-8" alt="reactangle" onClick={() => setShowLanguageDropDown(prevState => !prevState)} />
              </div>
              {showLanguageDropDown && (
                <div className="box-shadow absolute flag-dropdown mt-2">
                  <img src={Triangle} alt="triangle" />
                  <ul className="py-2.5 px-2 text-center">
                    {i18n.language == "en"
                      ?
                      <NavLink to="/ro">
                        <li className="flex items-center border-b-[0.5px]" onClick={() => {
                          changeLanguage('ro')
                        }}>
                          <img src={Romania} alt="romania" id="romania" className="w-8 h-8" />
                        </li>
                      </NavLink>
                      :
                      <NavLink to="/en">
                        <li className="flex items-center border-b-[0.5px]" onClick={() => {
                          changeLanguage('en')

                        }}>
                          <img src={UnitedKingdom} alt="uk" id="kingdom" className="w-8 h-8" />
                        </li>
                      </NavLink>
                    }
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>


        <div className="lg:hidden p-4 flex justify-between pl-6 pr-8 items-center">
          <img src={NavIcon} alt="nav" onClick={blockHandler} />
          <div className={blockclass}>
            <MobNavigation onClose={backHandler} />
          </div>
          <div className="ml-5" ref={mobileRef}>
            <div>
              <img src={i18n.language === "ro" ? Romania : UnitedKingdom} className="w-8 h-8" alt="reactangle" onClick={() => setShowLanguageDropDown(prevState => !prevState)} />
            </div>
            {showLanguageDropDown && (
              <div className="font-poppins box-shadow absolute flag-dropdown mt-2">
                <img src={Triangle} alt="triangle" />
                <ul className="py-2 px-2 text-center">
                  {i18n.language == "en"
                    ?
                    <NavLink to="/ro">
                      <li className="flex items-center border-b-[0.5px]" onClick={() => changeLanguage('ro')}>
                        <img src={Romania} alt="romania" className="w-8 h-8" />
                      </li>
                    </NavLink>
                    :
                    <NavLink to="/en">
                      <li className="flex items-center border-b-[0.5px]" onClick={() => changeLanguage('en')}>
                        <img src={UnitedKingdom} alt="uk" className="w-8 h-8" />
                      </li>
                    </NavLink>
                  }


                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;