export const PlanPriceData = [
  {
    id: 1,
    data: [
      {
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: require("../assets/images/tabo-incognito.png"),
        plan_price: 59,
        plan_currency_symbol: "€",
        info_restaurant: "Beach.infoBeach2",
        top_features: [
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          }
        ],
        plan_data: [
          {
            description: "Beach.Visibility",
            is_available: false,
          },
          {
            description: "Beach.Accept",
            is_available: false,
          },
          {
            description: "Beach.SetNotifications",
            is_available: true,
          },
          {
            description: "Beach.StoreReservations",
            is_available: true,
          },
          {
            description: "Beach.GenerateQR",
            is_available: true,
          },
          {
            description: "Beach.DayOrRange",
            is_available: true,
          },
          {
            description: "Beach.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Beach.ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Beach.Statistics",
            is_available: true,
          },
          {
            description: "Beach.MaxUsersUnlim",
            is_available: true,
          }
        ]
      },
      {
        primary_text_color: "#FA5865",
        primary_border_color: "#FA5865",
        plan_title: "KING",
        plan_image: require("../assets/images/tabo-king.png"),
        plan_price: 49,
        info_restaurant: "Beach.infoBeach1",
        top_features: [
          {
            key: "Beach.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [
          {
            description: "Beach.Visibility",
            is_available: true,
          },
          {
            description: "Beach.Accept",
            is_available: true,
          },
          {
            description: "Beach.SetNotifications",
            is_available: true,
          },
          {
            description: "Beach.StoreReservations",
            is_available: true,
          },
          {
            description: "Beach.GenerateQR",
            is_available: true,
          },
          {
            description: "Beach.DayOrRange",
            is_available: true,
          },
          {
            description: "Beach.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Beach.ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Beach.Statistics",
            is_available: true,
          },
          {
            description: "Beach.MaxUsers5",
            is_available: true,
          }
        ]
      },
      {
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: require("../assets/images/tabo-free.png"),
        plan_price: 0,
        plan_currency_symbol: "€",
        info_restaurant: "Beach.infoBeach3",
        top_features: [
          {
            key: "Beach.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [
          {
            description: "Beach.Visibility",
            is_available: true,
          },
          {
            description: "Beach.Accept",
            is_available: true,
          },
          {
            description: "Beach.SetNotifications",
            is_available: false,
          },
          {
            description: "Beach.StoreReservations",
            is_available: false,
          },
          {
            description: "Beach.GenerateQR",
            is_available: true,
          },
          {
            description: "Beach.DayOrRange",
            is_available: false,
          },
          {
            description: "Beach.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Beach.ProtocolDiscount",
            is_available: false,
          },
          {
            description: "Beach.Statistics2",
            is_available: true,
          },
          {
            description: "Beach.MaxUsers2",
            is_available: true,
          }
        ]
      },
    ]
  },
  {
    id: 2,
    data: [
      {
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: require("../assets/images/tabo-incognito.png"),
        plan_price: 59,
        plan_currency_symbol: "€",
        info_restaurant: "Pool.infoPool2",
        top_features: [
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          }
        ],
        plan_data: [
          {
            description: "Pool.Visibility",
            is_available: false,
          },
          {
            description: "Pool.Accept",
            is_available: false,
          },
          {
            description: "Pool.SetNotifications",
            is_available: true,
          },
          {
            description: "Pool.StoreReservations",
            is_available: true,
          },
          {
            description: "Pool.GenerateQR",
            is_available: true,
          },
          {
            description: "Pool.DayOrRange",
            is_available: true,
          },
          {
            description: "Pool.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Pool.ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Pool.Statistics",
            is_available: true,
          },
          {
            description: "Pool.MaxUsersUnlim",
            is_available: true,
          }
        ]
      },
      {
        primary_text_color: "#FA5865",
        primary_border_color: "#0b1b3559",
        plan_title: "KING",
        plan_image: require("../assets/images/tabo-king.png"),
        plan_price: 49,
        plan_currency_symbol: "€",
        info_restaurant: "Pool.infoPool1",
        top_features: [
          {
            key: "Pool.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [
          {
            description: "Pool.Visibility",
            is_available: true,
          },
          {
            description: "Pool.Accept",
            is_available: true,
          },
          {
            description: "Pool.SetNotifications",
            is_available: true,
          },
          {
            description: "Pool.StoreReservations",
            is_available: true,
          },
          {
            description: "Pool.GenerateQR",
            is_available: true,
          },
          {
            description: "Pool.DayOrRange",
            is_available: true,
          },
          {
            description: "Pool.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Pool.ProtocolDiscount",
            is_available: true,
          },
          {
            description: "Pool.Statistics",
            is_available: true,
          },
          {
            description: "Pool.MaxUsers5",
            is_available: true,
          }
        ]
      },
      {
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: require("../assets/images/tabo-free.png"),
        plan_price: 0,
        plan_currency_symbol: "€",
        info_restaurant: "Pool.infoPool3",
        top_features: [
          {
            key: "Pool.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [
          {
            description: "Pool.Visibility",
            is_available: true,
          },
          {
            description: "Pool.Accept",
            is_available: true,
          },
          {
            description: "Pool.SetNotifications",
            is_available: false,
          },
          {
            description: "Pool.StoreReservations",
            is_available: false,
          },
          {
            description: "Pool.GenerateQR",
            is_available: true,
          },
          {
            description: "Pool.DayOrRange",
            is_available: false,
          },
          {
            description: "Pool.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Pool.ProtocolDiscount",
            is_available: false,
          },
          {
            description: "Pool.Statistics2",
            is_available: true,
          },
          {
            description: "Pool.MaxUsers2",
            is_available: true,
          }
        ]
      },
    ]
  },
  {
    id: 3,
    data: [
      {
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: require("../assets/images/tabo-incognito.png"),
        plan_price: 99,
        plan_currency_symbol: "€",
        info_restaurant: "RestaurantPlan.infoRestaurant2",
        top_features: [
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          },
          {
            key: "RestaurantPlan.IncludedSMS",
            class: "text-red-500 italic"
          }
        ],
        note_information: {
          key: "RestaurantPlan.InfoSMS",
          class: "text-red-500 italic"
        },
        plan_data: [
          {
            description: "RestaurantPlan.AddAgenda",
            is_available: true,
          },
          {
            description: "RestaurantPlan.GenerateQR",
            is_available: true,
          },
          {
            description: "RestaurantPlan.SetNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ServerNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.BirthdayNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "RestaurantPlan.StoreReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ShowName",
            is_available: true,
          },
          {
            description: "RestaurantPlan.NumberOfReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ColitionBlinking",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "RestaurantPlan.LateEditTime",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryActions",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MonitorReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.Statistics",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MaxUsersUnlim",
            is_available: true,
          }
        ]
      },
      {
        primary_text_color: "#FA5865",
        primary_border_color: "#0b1b3559",
        plan_title: "KING",
        plan_image: require("../assets/images/tabo-king.png"),
        plan_price: 49,
        plan_currency_symbol: "€",
        info_restaurant: "RestaurantPlan.infoRestaurant1",
        top_features: [
          {
            key: "RestaurantPlan.CoastPerReservation",
            class: "color-black italic"
          },
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          },
          {
            key: "RestaurantPlan.IncludedSMS",
            class: "text-red-500 italic"
          }
        ],
        note_information: {
          key: "RestaurantPlan.InfoSMS",
          class: "text-red-500 italic"
        },
        plan_data: [

          {
            description: "RestaurantPlan.Visibility",
            is_available: true,
          },
          {
            description: "RestaurantPlan.Accept",
            is_available: true,
          },
          {
            description: "RestaurantPlan.GenerateQR",
            is_available: true,
          },
          {
            description: "RestaurantPlan.AddAgenda",
            is_available: true,
          },
          {
            description: "RestaurantPlan.SetNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ServerNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.BirthdayNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "RestaurantPlan.StoreReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ShowName",
            is_available: true,
          },
          {
            description: "RestaurantPlan.NumberOfReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ColitionBlinking",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "RestaurantPlan.LateEditTime",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryActions",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MonitorReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.Statistics",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MaxUsers5",
            is_available: true,
          }

        ]
      },
      {
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: require("../assets/images/tabo-free.png"),
        plan_price: 0,
        plan_currency_symbol: "€",
        info_restaurant: "RestaurantPlan.infoRestaurant3",
        top_features: [
          {
            key: "RestaurantPlan.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [

          {
            description: "RestaurantPlan.Visibility",
            is_available: true,
          },
          {
            description: "RestaurantPlan.Accept",
            is_available: true,
          },
          {
            description: "RestaurantPlan.GenerateQR",
            is_available: true,
          },
          {
            description: "RestaurantPlan.AddAgenda",
            is_available: false,
          },
          {
            description: "RestaurantPlan.SetNotifications",
            is_available: false,
          },
          {
            description: "RestaurantPlan.ServerNotifications",
            is_available: false,
          },
          {
            description: "RestaurantPlan.BirthdayNotifications",
            is_available: false,
          },
          {
            description: "RestaurantPlan.ManualorBulkSMS",
            is_available: false,
          },
          {
            description: "RestaurantPlan.StoreReservations",
            is_available: false,
          },
          {
            description: "RestaurantPlan.ShowName",
            is_available: true,
          },
          {
            description: "RestaurantPlan.NumberOfReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.ColitionBlinking",
            is_available: false,
          },
          {
            description: "RestaurantPlan.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "RestaurantPlan.LateEditTime",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryNotifications",
            is_available: true,
          },
          {
            description: "RestaurantPlan.HistoryActions",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MonitorReservations",
            is_available: true,
          },
          {
            description: "RestaurantPlan.Statistics2",
            is_available: true,
          },
          {
            description: "RestaurantPlan.MaxUsers2",
            is_available: true,
          },

        ]
      },
    ]
  },
  {
    id: 4,
    data: [
      {
        primary_text_color: "#57A609",
        primary_border_color: "#57A609",
        plan_title: "INCOGNITO",
        plan_image: require("../assets/images/tabo-incognito.png"),
        plan_price: 99,
        plan_currency_symbol: "€",
        info_restaurant: "Club.infoClub2",
        top_features: [
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          },
          {
            key: "RestaurantPlan.IncludedSMS",
            class: "text-red-500 italic"
          }
        ],
        plan_data: [
          {
            description: "Club.AddAgenda",
            is_available: true,
          },
          {
            description: "Club.GenerateQR",
            is_available: true,
          },
          {
            description: "Club.SetNotifications",
            is_available: true,
          },
          {
            description: "Club.ServerNotifications",
            is_available: true,
          },
          {
            description: "Club.BirthdayNotifications",
            is_available: true,
          },
          {
            description: "Club.ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "Club.StoreReservations",
            is_available: true,
          },
          {
            description: "Club.ShowName",
            is_available: true,
          },
          {
            description: "Club.NumberOfReservations",
            is_available: true,
          },
          {
            description: "Club.ColitionBlinking",
            is_available: true,
          },
          {
            description: "Club.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Club.LateEditTime",
            is_available: true,
          },
          {
            description: "Club.HistoryNotifications",
            is_available: true,
          },
          {
            description: "Club.HistoryActions",
            is_available: true,
          },
          {
            description: "Club.MonitorReservations",
            is_available: true,
          },
          {
            description: "Club.Statistics",
            is_available: true,
          },
          {
            description: "Club.MaxUsersUnlim",
            is_available: true,
          }
        ],
        note_information: {
          key: "Club.InfoSMS",
          class: "text-red-500 italic"
        },
      },
      {
        primary_text_color: "#FA5865",
        primary_border_color: "#0b1b3559",
        plan_title: "KING",
        plan_image: require("../assets/images/tabo-king.png"),
        plan_price: 49,
        plan_currency_symbol: "€",
        info_restaurant: "Club.infClub1",
        top_features: [
          {
            key: "RestaurantPlan.CoastPerReservation",
            class: "color-black italic"
          },
          {
            key: "RestaurantPlan.FreeTrial",
            class: "text-red-500 italic"
          },
          {
            key: "RestaurantPlan.IncludedSMS",
            class: "text-red-500 italic"
          }
        ],
        plan_data: [
          {
            description: "Club.Visibility",
            is_available: true,
          },
          {
            description: "Club.Accept",
            is_available: true,
          },
          {
            description: "Club.GenerateQR",
            is_available: true,
          },
          {
            description: "Club.AddAgenda",
            is_available: true,
          },
          {
            description: "Club.SetNotifications",
            is_available: true,
          },
          {
            description: "Club.ServerNotifications",
            is_available: true,
          },
          {
            description: "Club.BirthdayNotifications",
            is_available: true,
          },
          {
            description: "Club.ManualorBulkSMS",
            is_available: true,
          },
          {
            description: "Club.StoreReservations",
            is_available: true,
          },
          {
            description: "Club.ShowName",
            is_available: true,
          },
          {
            description: "Club.NumberOfReservations",
            is_available: true,
          },
          {
            description: "Club.ColitionBlinking",
            is_available: true,
          },
          {
            description: "Club.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Club.LateEditTime",
            is_available: true,
          },
          {
            description: "Club.HistoryNotifications",
            is_available: true,
          },
          {
            description: "Club.HistoryActions",
            is_available: true,
          },
          {
            description: "Club.MonitorReservations",
            is_available: true,
          },
          {
            description: "Club.Statistics",
            is_available: true,
          },
          {
            description: "Club.MaxUsers5",
            is_available: true,
          }
        ],
        note_information: {
          key: "Club.InfoSMS",
          class: "text-red-500 italic"
        },
      },
      {
        primary_text_color: "#4E4E57",
        primary_border_color: "#0b1b3559",
        plan_title: "FREE",
        plan_image: require("../assets/images/tabo-free.png"),
        plan_price: 0,
        plan_currency_symbol: "€",
        info_restaurant: "Club.infoClub3",
        top_features: [
          {
            key: "RestaurantPlan.CoastPerReservation",
            class: "color-black italic"
          }
        ],
        plan_data: [
          {
            description: "Club.Visibility",
            is_available: true,
          },
          {
            description: "Club.Accept",
            is_available: true,
          },
          {
            description: "Club.GenerateQR",
            is_available: true,
          },
          {
            description: "Club.AddAgenda",
            is_available: false,
          },
          {
            description: "Club.SetNotifications",
            is_available: false,
          },
          {
            description: "Club.ServerNotifications",
            is_available: false,
          },
          {
            description: "Club.BirthdayNotifications",
            is_available: false,
          },
          {
            description: "Club.ManualorBulkSMS",
            is_available: false,
          },
          {
            description: "Club.StoreReservations",
            is_available: false,
          },
          {
            description: "Club.ShowName",
            is_available: true,
          },
          {
            description: "Club.NumberOfReservations",
            is_available: true,
          },
          {
            description: "Club.ColitionBlinking",
            is_available: false,
          },
          {
            description: "Club.ChangeSwitchReserv",
            is_available: true,
          },
          {
            description: "Club.LateEditTime",
            is_available: true,
          },
          {
            description: "Club.HistoryNotifications",
            is_available: true,
          },
          {
            description: "Club.HistoryActions",
            is_available: true,
          },
          {
            description: "Club.MonitorReservations",
            is_available: true,
          },
          {
            description: "Club.Statistics2",
            is_available: true,
          },
          {
            description: "Club.MaxUsers2",
            is_available: true,
          },
        ]
      },
    ]
  },
]