import React from "react";
import { Link } from 'react-scroll';
import { useTranslation } from "react-i18next";

import Logo from '../../../assets/images/logo.png';
import Instagram from '../../../assets/images/instagram.png';
import AppleIcon from '../../../assets/images/iphoneplay.png';
import GooglePlay from '../../../assets/images/Google-Play.png';
import Facebook from '../../../assets/images/fa-facebook-square.png';
import MobileGooglePlay from '../../../assets/images/moble-gplay.png';
import MobileAppleIcon from '../../../assets/images/mobile-iphone-play.png';

import '../../../assets/common.css';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="xl:w-full xl:max-w-[1440px] mx-auto font-poppins bg-[#F9F9F9]">
      <div className="xl:px-2 pb-1 sm:pb-2 pt-2 sm:pt-6 lg:px-10 px-5">
        <div className="upper-part flex items-center justify-between lg:flex-row flex-col">
          <div className="flex items-center w-full gap-4 justify-between flex-wrap">
            <ul className="flex text-[#1E0E62] sm:justify-between flex-wrap items-center">
              <li className="sm:text-base text-xs px-1 sm:px-2 sm:py-0 py-2"><Link to="main-banner" spy={true} smooth={true}>{t("Footer.home")}</Link></li>
              <li className="sm:text-base text-xs px-1 sm:px-2 sm:py-0 py-2"><Link to="what-is-tabo" spy={true} smooth={true}>{t("Footer.about")}</Link></li>
              <li className="sm:text-base text-xs px-1 sm:px-2 sm:py-0 py-2"><Link to="trusted-by" spy={true} smooth={true}>{t("Footer.TrustedBy")}</Link></li>
              <li className="sm:text-base text-xs px-1 sm:px-2 sm:py-0 py-2"><Link to="pricing-plan" spy={true} smooth={true}>{t("Footer.pricing")}</Link></li>
              <li className="sm:text-base text-xs px-1 sm:px-2 sm:py-0 py-2"><Link to="contact-us" spy={true} smooth={true}>{t("Footer.contactUs")}</Link></li>
            </ul>
          </div>
        </div>
      <div className="border-t-2 pt-4 w-[81%] mx-auto"></div>
      <div className="lower-part xl:px-28 lg:px-10 px-7 ">
        <div className="flex justify-between pb-5 md:items-center md:flex-row flex-col">
          <div className="lg:pb-0 pb-3">
            <p className="text-[#1E0E62] sm:text-base text-xs mb-1">{t("Footer.socialLabel1")}:</p>
            <div className="flex">
              <a href="https://play.google.com/store/apps/details?id=com.tabo" target={"_blank"} className="pr-3" rel="noopener noreferrer">
                <img className="sm:block hidden h-8" src={GooglePlay} alt="g-play" />
                <img className="sm:hidden block h-8" src={MobileGooglePlay} alt="m-g-play" />
              </a>
              <a href="https://apps.apple.com/sz/app/tabo/id1580936893" target={"_blank"} rel="noopener noreferrer">
                <img className="sm:block hidden h-8" src={AppleIcon} alt="i-play" />
                <img className="sm:hidden block h-8" src={MobileAppleIcon} alt="i-play" />
              </a>
            </div>
          </div>
          <div className="lg:pb-0 pb-5">
            <p className="text-[#1E0E62] sm:text-base text-xs mb-1 ">{t("Footer.socialLabel2")}:</p>
            <div className="flex">
              <a href="https://play.google.com/store/apps/details?id=com.hostess&hl=en&gl=US" target={"_blank"} className="pr-3" rel="noopener noreferrer">
                <img className="sm:block hidden h-8" src={GooglePlay} alt="g-play" />
                <img className="sm:hidden block h-8" src={MobileGooglePlay} alt="m-g-play" />
              </a>
              <a href="https://apps.apple.com/ro/app/tabo-business/id1534772063" target={"_blank"} rel="noopener noreferrer">
                <img className="sm:block hidden h-8" src={AppleIcon} alt="i-play" />
                <img className="sm:hidden block h-8" src={MobileAppleIcon} alt="i-play" />
              </a>
            </div>
          </div>
          <div className="w-full sm:max-w-[200px] max-w-[210px]">
            <ul className="flex sm:justify-between pr-5 lg:pl-32 items-center">
              <li className="pr-4  sm:pr-0">
                <a href="https://www.instagram.com/tabo.app/" target={"_blank"} rel="noopener noreferrer">
                  <img src={Instagram} className="w-[19px] h-[20px]" alt="Instagram" />
                </a>
              </li>
              <li className="pr-4 sm:pr-0">
                <a href="https://www.facebook.com/TABO-App-106199508333030" target={"_blank"} rel="noopener noreferrer">
                  <img src={Facebook} className="w-[19px] h-[20px]" alt="Facebook" />
                </a>
              </li>
            </ul>
          </div>
          <div className="br_class">
              <p className="text-[#1E0E62] text-xs pb-2 sm:pb-0 pt-4 sm:pt-6 px-1 sm:px-2 sm:py-0 py-0">{t("Footer.rights")}</p>
            </div>
      </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;