import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.css';
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));

const SEOTags = () => {
  return (
    <Helmet>
      <title>Tabo</title>
      <meta name="title" content="Tabo" />
      <meta name="description"
        content="TABO helps you reserve sunbeds, beds or tables, having the appropriate information about a place, whether it's a beach, pool, restaurant terrace or club." />
      <meta name="keywords"
        content="tabo,restaurant,client,restaurants,pool,app,pools,beach,beaches,terrace,restaurant terrace,menu,tables booking,seat booking,booking,bookings,
        reserve,reservation,online reservations,reservations,sunbed,sunbeds,table,tables,place,club,bar,grid,business,business informations,application,services,plans,plan,management,management systems,booklet,schedule,monitoring,access,according,
        appropriate,approved,contact,customers,employees,notifications,offers,trusted,users,store" />
      <meta property="og:site_name" content="Tabo" />
      {/* facebook */}
      <meta property="og:title" content="Tabo" />
      <meta property="og:url" content="https://tabo.app/en" />
      <meta property="og:type" content="website" />
      <meta property="og:description"
        content="TABO helps you reserve sunbeds, beds or tables, having the appropriate information about a place, whether it's a beach, pool, restaurant terrace or club." />
      <meta property="og:image:width" content="240" />
      <meta property="og:image:height" content="480" />
      <meta property="og:image" name="image" content="https://play-lh.googleusercontent.com/Z3mdrKYbt3zAY1u9p1zvpyEzKhmYIzwHRFCYJu0hVBUs9uWJO2JwVRUYe9tXN1_Erg=w240-h480-rw" />
      {/* end facebook */}
      {/* twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Tabo" />
      <meta name="twitter:description"
        content="TABO helps you reserve sunbeds, beds or tables, having the appropriate information about a place, whether it's a beach, pool, restaurant terrace or club." />
      <meta name="twitter:url" content="https://tabo.app/en" />
      <meta name="twitter:image" content="https://play-lh.googleusercontent.com/Z3mdrKYbt3zAY1u9p1zvpyEzKhmYIzwHRFCYJu0hVBUs9uWJO2JwVRUYe9tXN1_Erg=w240-h480-rw" />
      {/* end twitter */}
    </Helmet>
  );
};

root.render(
  <HelmetProvider>
    <SEOTags />
    <App />
  </HelmetProvider>
);

reportWebVitals();
