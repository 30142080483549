import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

import PageTitle from '../../common/PageTitle';

import "react-multi-carousel/lib/styles.css";
import '../../../assets/common.css'

const WhyTabo = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 250,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    }
  };

  const data = [
    {
      id: 1,
      img: require("../../../assets/images/goal.png"),
      title: "WhyTabo.titlepara1",
      description: "WhyTabo.para1",
    },
    {
      id: 2,
      img: require("../../../assets/images/vision.png"),
      title: "WhyTabo.titlepara2",
      description: "WhyTabo.para2",
    },
    {
      id: 3,
      img: require("../../../assets/images/principles.png"),
      title: "WhyTabo.titlepara3",
      description: "WhyTabo.para3",
    },
  ];

  return (
    <>
      <div className="w-full md:max-w-[1190px] md:pb-40 mx-auto px-7 bg-white hidden lg:block">
        <div className="w-full font-poppins">
          <div className="text-center">
            <PageTitle title1={t("WhyTabo.ourVision")} title2="&" title3={t("WhyTabo.ourMission")} />
            <div className="flex lg:flex-nowrap lg:items-stretch flex-wrap items-center justify-center">
              {
                data.map(d => (
                  <div key={`${d.id}`} className="flex lg:w-4/12 h-auto sm:w-96 md:mx-0 w-[95%] mx-auto md:pr-7 md:mt-4 mt-5">
                    <div className="flex flex-col bg-white h-full text-center rounded-[28px] goalBoxShadow pt-7 md:pb-10 pb-9 lg:px-6 px-4 lg:min-h-[380px]">
                      <div className="md:h-18 h-12">
                        <img className="mx-auto h-full" src={d.img} alt={d.title} />
                      </div>
                      <h3 className="md:text-2xl text-lg font-normal leading-7 text-black md:mt-10 mt-5 md:mb-6 mb-1.5">{t(d.title)}</h3>
                      <p className="md:text-base md:font-normal text-xs font-light leading-7 text-black lg:pb-1">{t(d.description)}</p>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>

      <div className='services-carousel why-tabo-new pb-10 mx-auto px-0 service-top block lg:hidden'>
        <PageTitle title1={t("WhyTabo.ourVision")} title2="&" title3={t("WhyTabo.ourMission")} />
        <Carousel
          showDots
          swipeable
          draggable
          partialVisible
          keyBoardControl
          autoPlay={false}
          responsive={responsive}
          transitionDuration={500}
          customTransition="all .5"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          beforeChange={(nextSlide) => setActiveIndex(nextSlide)}
          containerClass={`carousel-container pl-[10px] ${activeIndex == data.length - 1 ? "pl-0" : ""} md:!pl-0`}
          sliderClass={`pb-[0px] md:!pl-[130px] ${activeIndex == data.length - 1 ? "md:left-[-240px] left-[-10px]" : ""}`}
        >
          {
            data.map(d => (
              <div key={`${d.id}`} className="w-[100%] md:w-[95%] h-full rounded-lg font-poppins">
                <div className="md:px-7 h-full sm:w-auto w-72 mx-auto sm:text-left bg-white text-center rounded-[28px] pb-5 lg:px-6 px-4 lg:h-[502px] newBoxShadow">
                  <img className="mx-auto h-20" src={d.img} alt={d.title} />
                  <h3 className="md:text-4xl text-lg font-normal leadin-6 text-black md:mt-10 mt-4 md:mb-6 mb-1 md:text-center">{t(d.title)}</h3>
                  <p className="md:text-2xl md:font-normal text-xs font-light text-black lg:pb-1">{t(d.description)}</p>
                </div>
              </div>
            ))
          }
        </Carousel>
      </div>
    </>
  )
}

export default WhyTabo;