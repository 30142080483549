import React from "react";
import WhatIsTaboIcon from '../../../assets/images/whatIsTabo.png';
import { useTranslation } from "react-i18next";
import PageTitle from "../../common/PageTitle";

const WhatIsTabo = () => {
    const { t } = useTranslation();

    return (
        <div className="md:max-w-[1190px] mx-auto px-3 bg-white pb-10 md:pb-40 font-poppins" id="what-is-tabo">
            <PageTitle title1={t("WhatIsTabo.title1")} title2={t("WhatIsTabo.title2")} />
            <div className="flex md:flex-row flex-col-reverse">
                <div className="lg:ml-9 lg:w-10/12 w-full lg:pr-3 lg:mb-0 lg:mt-8 lg:px-0 px-4">
                    <img className="float-left h-[200px] md:h-[250px] mr-[15px]" src={WhatIsTaboIcon} alt="Tabo" />
                    <div>
                        <p className="sm:text-base text-xs sm:font-normal font-light sm:leading-7 leading-5 text-black mb-4">{t("WhatIsTabo.para1")}</p>
                        <p className="sm:text-base text-xs sm:font-normal font-light sm:leading-7 leading-5 text-black mb-4">{t("WhatIsTabo.para2")}</p>
                        <p className="sm:text-base text-xs sm:font-normal font-light sm:leading-7 leading-5 text-black mb-0">{t("WhatIsTabo.para3")}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhatIsTabo;