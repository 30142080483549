import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageTitle from "../../common/PageTitle";

import httpService from "../../../services/http";
import i18n from "../../../i18n";

import YoutubeIcon from "../../../assets/images/youtube.png";

const Tutorial = (props) => {
  const { tutorials = [], onReadBtnPress, onVideoBtnPress, currentLanguage } = props;
  const numberOfData = 5;
  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const hasMoreData = () => {
    const nextPageNo = page + 1;
    const nextDataLength = nextPageNo * numberOfData;
    return {
      hasData: ((tutorials.length > (page * numberOfData) && tutorials.length < nextDataLength) || (tutorials.length >= nextDataLength)),
      nextPageNo,
    };
  }

  const onLoadMoreClick = () => {
    if (hasMoreData().hasData) {
      setPage(hasMoreData().nextPageNo);
    }
  };

  const onResetClick = () => {
    setPage(1);
  };

  const renderTutorial = (tutorial) => {
    const { id } = tutorial;
    return (
      <div key={`${id}`} className="bg-white shadow-md shadow-gray-400 px-5 py-3 mb-5 rounded-md">
        <h4 className="text-lg font-semibold">{tutorial[`name_${currentLanguage}`]}</h4>
        <div className="flex items-center justify-end mt-2">
          {
            tutorial[`description_${currentLanguage}`] ? (
              <button
                onClick={() => onReadBtnPress(tutorial)}
                className="text-sm flex items-center justify-center h-8 rounded-lg text-center text-[#6844F9] hover:text-white border-2 border-[#6844F9] px-3 mr-3"
              >
                {t("read")}
              </button>
            ) : null
          }
          {
            tutorial?.video_url ? (
              <button
                onClick={() => onVideoBtnPress(tutorial)}
                className="flex items-center justify-center rounded-lg h-8 hover:bg-white"
              >
                <img src={YoutubeIcon} className="h-10" alt="youtube"/>
              </button>
            ) : null
          }
        </div>
      </div>
    );
  }

  const tutorialDisplayData = [...tutorials].splice(0, page * numberOfData);
  return (
    <Fragment>
      <div className="block sm:hidden">
        {tutorialDisplayData.map(renderTutorial)}
      </div>
      <div className="sm:block hidden">
        <table className="table-auto mx-auto">
          <tbody>
            {
              tutorialDisplayData.map(tutorial => (
                <tr key={`${tutorial.id}`} className="flex mb-2 w-full border-b-2 bg-white border-b-gray-400 sm:border-b-0 sm:shadow-lg sm:shadow-gray-400 sm:rounded-md">
                  <td className="w-full pl-3 pr-10 py-3 min-w-[20rem] mr-2">{tutorial[`name_${currentLanguage}`]}</td>
                  <td className="flex items-center justify-end">
                    {
                      tutorial[`description_${currentLanguage}`] ? (
                        <button onClick={() => onReadBtnPress(tutorial)} className="text-sm flex items-center justify-center h-8 w-20 rounded-lg text-center text-[#6844F9] hover:text-white border-2 border-[#6844F9] px-3">
                          {t("read")}
                        </button>
                      ) : null
                    }
                    {
                      tutorial?.video_url ? (
                        <button onClick={() => onVideoBtnPress(tutorial)} className="flex items-center justify-center rounded-lg h-8 w-20 hover:bg-white px-3">
                          <img src={YoutubeIcon} className="object-contain h-full w-full" alt="youtube"/>
                        </button>
                      ) : null
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      <div className="flex items-center justify-center gap-3 mt-10">
        {
          hasMoreData().hasData ? (
            <button
              onClick={onLoadMoreClick}
              disabled={!hasMoreData().hasData}
              className="px-3 py-1 border-2 border-[#6844F9] disabled:border-gray-500 disabled:bg-gray-400 rounded-lg hover:text-white hover:disabled:text-black disabled:cursor-not-allowed text-lg"
            >
              {t("Tutorials.Load")}
            </button>
          ) : null
        }
        {
          page > 1 ? (
            <button
              onClick={onResetClick}
              className="px-3 py-1 border-2 border-[#6844F9] disabled:border-gray-500 disabled:bg-gray-400 rounded-lg hover:text-white hover:disabled:text-black disabled:cursor-not-allowed text-lg"
            >
              {t("Tutorials.Reset")}
            </button>
          ) : null
        }
      </div>
    </Fragment>
  );
};

const Tutorials = () => {
  const { t } = useTranslation();

  const [showVideo, setShowVideo] = useState(false);
  const [allTutorials, setAllTutorials] = useState([]);
  const [selectedTutorial, setSelectedTutorial] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [showTutorialDescription, setShowTutorialDescription] = useState(false);

  useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    getTutorials();
  }, []);

  const getTutorials = async () => {
    try {
      const response = await httpService.get("/getAllTutorials");
      const { data = [] } = response;
      if (data) {
        let bt = [], rt = [], ct = [];
        _.sortBy(data.map(d => ({ ...d, index: Number(d.index) })), ["index"]).forEach(d => {
          if (d?.type == "club") {
            ct.push(d);
          } else if (d?.type == "beach") {
            bt.push(d);
          } else if (d?.type == "restaurant") {
            rt.push(d);
          }
        });
        setAllTutorials([
          { type: "beach", tutorials: bt },
          { type: "restaurant", tutorials: rt },
          { type: "club", tutorials: ct },
        ]);
      }
    } catch (error) { }
  };

  const onVideoBtnPress = (data) => {
    setSelectedTutorial(data);
    setShowVideo(true);
  };

  const hideVideoModal = () => {
    setShowVideo(false);
    setSelectedTutorial({});
  };

  const onReadBtnPress = (data) => {
    setSelectedTutorial(data);
    setShowTutorialDescription(true);
  };

  const hideReadModal = () => {
    setShowTutorialDescription(false);
    setSelectedTutorial({});
  };

  const renderTutorials = (tutorial) => {
    const { tutorials = [], type } = tutorial;
    return (
      <TabPanel key={type}>
        <Tutorial
          tutorials={tutorials}
          onReadBtnPress={onReadBtnPress}
          currentLanguage={currentLanguage}
          onVideoBtnPress={onVideoBtnPress}
        />
      </TabPanel>
    );
  };

  if (!allTutorials.length || (!allTutorials[0]?.tutorials.length && !allTutorials[1]?.tutorials.length && !allTutorials[2]?.tutorials.length)) return null;

  return (
    <div className="w-full md:max-w-[1190px] md:pb-40 pb-10 mx-auto px-7 bg-white">
      <PageTitle title2={t("Tutorials.title")} />
      <div className="w-full flex justify-center mt-3 sm:mt-10">
        <Tabs className="w-full max-w-1330px react-tabs-ul">
          <TabList className="flex justify-center px-5 pb-5">
            <Tab className="border w-full text-center first-tab py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] md:w-36 customFirstRounded">{t("PricingPlan.Beach")}</Tab>
            <Tab className="border w-full text-center md:w-36 py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] rounded-none">{t("PricingPlan.Restaurant")}</Tab>
            <Tab className="border w-full text-center md:w-36 py-2 sm:py-4 font-medium text-[10px] sm:text-base text-[#15143966] customLastRounded">{t("PricingPlan.Club")}</Tab>
          </TabList>
          {allTutorials.map(renderTutorials)}
        </Tabs>
      </div>
      {
        showVideo ? (
          <Fragment>
            <div
              className="overflow-x-hidden overflow-y-hidden fixed inset-0 z-50"
            >
              <div className={`relative w-full h-full bg-black`}>
                <div className="h-[5%] w-full border-b border-solid border-slate-200 overflow-hidden video-header">
                  <button
                    onClick={hideVideoModal}
                    className="float-right h-full font-semibold hover:bg-transparent mr-1"
                  >
                    <span className="text-white text-2xl">
                      ×
                    </span>
                  </button>
                </div>
                <div className="h-[93%] w-full video-body">
                  <iframe
                    width={"100%"}
                    height={"100%"}
                    frameBorder="0"
                    allowfullscreen={true}
                    title={`${selectedTutorial[`name_${currentLanguage}`]}`}
                    src={`${selectedTutorial.video_url}?rel=0&autoplay=1&start=0&ecver=1`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  />
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-hidden"></div>
          </Fragment>
        ) : null
      }
      {
        showTutorialDescription ? (
          <Fragment>
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none font-poppins">
              <div className={`relative w-auto my-3 mx-auto max-w-3xl min-w-[18rem] sm:min-w-[48rem]`}>
                <div className={`border-0 rounded-lg shadow-lg pb-3 relative flex flex-col mx-auto bg-white outline-none focus:outline-none w-[95%] sm:w-full`}>
                  <div className="flex items-start justify-between pl-3 pr-2 py-2 border-b border-solid border-slate-200 rounded-t gap-2">
                    <h3 className={"block text-xl font-semibold modal-header-title"}>
                      {`${selectedTutorial[`name_${currentLanguage}`]}`}
                    </h3>
                    <button
                      onClick={hideReadModal}
                      className="font-semibold text-3xl hover:bg-transparent"
                    >
                      ×
                    </button>
                  </div>
                  <div
                    className={`relative px-3 pt-2 flex-auto max-w-[100vw] max-h-[75vh] sm:max-h-[60vh] overflow-y-auto break-words`}
                    dangerouslySetInnerHTML={{ __html: `${selectedTutorial[`description_${currentLanguage}`]}` }}
                  >
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-hidden"></div>
          </Fragment>
        ) : null
      }
    </div>
  );
};

export default Tutorials;