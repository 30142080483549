import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import PageTitle from "../../common/PageTitle";

import httpService from "../../../services/http";

import "react-multi-carousel/lib/styles.css";

const TrustedBy = () => {
  const { t } = useTranslation();
  const [allCustomers, setAllCustomers] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await httpService.get("/getAllLogos");
      const { data = [] } = response;
      setAllCustomers(data);
    } catch (error) { }
  };

  return (
    <div id="trusted-by" className="w-full md:max-w-[1390px] mx-auto px-10 pb-10 md:pb-40 bg-white sm:mb-15">
      <div className="xl:px-14 lg:px-9 px-2">
        <PageTitle title1={t("TrustedBy.title1")} title2={t("TrustedBy.title2")} />
        <div className="grid grid-cols-3 md:grid-cols-6 gap-4">
          {allCustomers.map((c, i) => (
            <div key={`${c.logo}-${i}`} className="flex items-center justify-center">
              <img
                src={c.logo}
                className="aspect-auto"
                alt="logo"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default TrustedBy;