import React, { useEffect } from 'react'
import { GoogleMap, Marker, LoadScript } from "@react-google-maps/api";

function MapComponent(props) {
  const [isMounted, setIsMounted] = React.useState(false);
  useEffect(() => {
    setIsMounted(true)
  }, []);

  return (
    <div className="wrapper">
      <div className="map_container rounded-lg">
        <LoadScript googleMapsApiKey="AIzaSyBAQpW7AAOUhRjIX3U9WaXkSAHfXHSZvhE">
          <GoogleMap
            id="map"
            zoom={16}
            center={{ lat: props.lat, lng: props.lng }}
            mapContainerClassName={"w-auto h-[400px] rounded-lg bg-white shadow-inner"}
          >
            {isMounted && <Marker position={{ lat: props.lat, lng: props.lng }} />}
          </GoogleMap>
        </LoadScript>
      </div>
    </div>
  );
};

export default MapComponent;
