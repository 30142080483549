import React from "react";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

import PageTitle from "../../common/PageTitle";

import Place from '../../../assets/images/place.png';
import Service1 from '../../../assets/images/service1.png';
import PoolClub from '../../../assets/images/pool-club.png';
import BeachPool from '../../../assets/images/beach-pool.png';
import SeatsReserve from '../../../assets/images/seats-reserve.png';

import "react-multi-carousel/lib/styles.css";
import '../../../assets/common.css'

const OurServices = () => {
  const { t } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    }
  };

  return (
    <>
      <div className="w-full max-w-2xl mx-auto px-2.5 md:pb-40 bg-white hidden sm:block">
        <div className="w-full mb-5 font-poppins">
          <PageTitle title1={t("OurServices.title1")} title2={t("OurServices.title2")} />
          <div className="md:mt-40 mt-10">
            <div className="md:flex mb-10">
              <div className="flex justify-center items-center relative md:-top-20 lg:mr-10 md:mr-4 md:w-[360px] sm:w-96 md:ml-0 mx-auto md:h-[320px] w-72 h-[273]">
                <img className="w-full h-full" src={SeatsReserve} alt="seats" />
                <div className="absolute text-center w-72 mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins">{t("OurServices.img1")}</p>
                </div>
              </div>
              <div className="flex justify-center items-center relative md:mt-0 mt-8 md:w-[360px] sm:w-96 md:ml-0 mx-auto md:h-[320px] w-72 h-[273]">
                <img className="w-full h-full" src={BeachPool} alt="beach pool" />
                <div className="absolute text-center mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins">{t("OurServices.img2")}</p>
                </div>
              </div>
            </div>
            <div className="md:flex">
              <div className="flex justify-center items-center relative md:mt-0 mt-8 md:-top-20 lg:mr-10 md:mr-4 md:w-[360px] sm:w-96 md:ml-0 mx-auto md:h-[320px] w-72 h-[273]">
                <img className="w-full h-full" src={PoolClub} alt="pool club" />
                <div className="absolute text-center mt-6 w-72">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins">{t("OurServices.img3")}</p>
                </div>
              </div>
              <div className="flex justify-center items-center relative md:mt-0 mt-8 md:w-[360px] sm:w-96 md:ml-0 mx-auto md:h-[320px] w-72 h-[273]">
                <img className="w-full h-full" src={Place} alt="place" />
                <div className="absolute text-center mt-6 w-82">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[142px] font-poppins font-medium font-poppins">{t("OurServices.img4")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='services-carousel block sm:hidden pb-10 font-poppins'>
        <PageTitle title1={t("OurServices.title1")} title2={t("OurServices.title2")} />
        <div className='services-carousel why-tabo-new service-top my-services block sm:hidden'>
          <Carousel
            showDots
            infinite
            swipeable
            draggable
            keyBoardControl
            autoPlay={false}
            autoPlaySpeed={1000}
            partialVisible={false}
            responsive={responsive}
            transitionDuration={500}
            customTransition="all .5"
            sliderClass={"left-[15px]"}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <div className="app-slider-item">
              <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-96 md:ml-0 mx-auto md:h-[472px] w-72">
                <img className="w-full h-full" src={Service1} />
                <div className="absolute text-center w-72 mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins mt-[180px] max-w-[186px] mx-auto">{t("OurServices.img1")}</p>
                </div>
              </div>
            </div>
            <div className="app-slider-item">
              <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-96 md:ml-0 mx-auto md:h-[472px] w-72">
                <img className="w-full h-full" src={BeachPool} alt="beach pool" />
                <div className="absolute text-center w-72 mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins mt-[180px] max-w-[186px] mx-auto">{t("OurServices.img2")}</p>
                </div>
              </div>
            </div>
            <div className="app-slider-item">
              <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-96 md:ml-0 mx-auto md:h-[472px] w-72">
                <img className="w-full h-full" src={PoolClub} alt="pool club" />
                <div className="absolute text-center w-72 mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins mt-[180px] max-w-[186px] mx-auto">{t("OurServices.img3")}</p>
                </div>
              </div>
            </div>
            <div className="app-slider-item">
              <div className="flex justify-center items-center relative lg:mr-10 md:mr-4 sm:w-96 md:ml-0 mx-auto md:h-[472px] w-72">
                <img className="w-full h-full" src={Place} alt="place" />
                <div className="absolute text-center w-72 mt-6">
                  <p className="md:text-4xl text-lg font-meidum md:leading-9 leading-5 text-white h-[72px] font-poppins font-medium font-poppins mt-[180px] max-w-[186px] mx-auto">{t("OurServices.img4")}</p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </>
  )
}

export default OurServices;