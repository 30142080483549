import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import { useTranslation } from "react-i18next";

import PageTitle from "../../common/PageTitle";

import '../../../assets/common.css';
import "react-multi-carousel/lib/styles.css";

const HowDoesTabo = () => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const data = [
    {
      id: 1,
      img: require("../../../assets/images/ant-design.png"),
      description: "HowDoesTabo.text1",
    },
    {
      id: 2,
      img: require("../../../assets/images/speaker.png"),
      description: "HowDoesTabo.text2",
    },
    {
      id: 3,
      img: require("../../../assets/images/laptop.png"),
      description: "HowDoesTabo.text3",
    },
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 250,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    }
  }

  return (
    <>
      <div className="w-full md:max-w-[1190px] mx-auto font-poppins pb-10 md:pb-40">
        <div className="w-full px-0 sm:px-11 font-poppins">
          <PageTitle title1={t("HowDoesTabo.title1")} title2={t("HowDoesTabo.title2")} component="h1"/>
          <div className="lg:flex lg:flex-nowrap flex-wrap justify-center items-center mt-7 hidden">
            {
              data.map(d => (
                <div key={`${d.id}`} className="lg:w-4/12 md:w-80 w-56 md:pr-5 md:pl-0 px-0.5 mt-5 md:mx-0 mx-auto">
                  <div className="border-2 border-[#ebeaed] rounded-[10px] md:pt-16 pt-10 md:pb-12 pb-11 xl:px-10 px-4 text-center dropShadow">
                    <div className="mb-2 sm:h-20 h-11">
                      <img className="mx-auto sm:h-auto h-full" src={d.img} alt="Mobile" />
                    </div>
                    <p className="md:text-base text-[10px] font-normal md:leading-6 leading-4 text-[#1514397a] md:h-[9rem] h-20 overflow-y-auto">{t(d.description)}</p>
                  </div>
                </div>
              ))
            }
          </div>
          <div className='how-does-tabo-carousel block lg:hidden'>
            <Carousel
              showDots
              swipeable
              draggable
              autoPlay={false}
              autoPlaySpeed={1000}
              partialVisible={true}
              keyBoardControl={true}
              responsive={responsive}
              transitionDuration={500}
              customTransition="all .5"
              itemClass="slider-paddingApp2"
              dotListClass="custom-dot-list-style"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              beforeChange={(nextSlide) => setActiveIndex(nextSlide)}
              sliderClass={`md:!pl-[120px] ${activeIndex == data.length - 1 ? "md:left-[-240px] left-[-25px]" : ""}`}
              containerClass={`slider-paddingHowDoesTaboContainer2 pl-[25px] ${activeIndex == data.length - 1 ? "pl-[0px]" : ""} md:!pl-0`}
            >
              {
                data.map(d => {
                  return (
                    <div key={`${d.id}`} className="w-full h-full rounded-lg slider-bottom-howDoesTabo">
                      <div className="sm:mt-5 mt-2.5 h-full md:py-5 py-2.5 sm:w-auto w-auto text-left">
                        <div className="border-2 h-full border-[#ebeaed] rounded-[10px] pt-10 pb-5 xl:px-10 px-4 text-center dropShadow howDoesTaboConfigure">
                          <div className="mb-2 md:mb-5 sm:h-20 md:!h-[60px]">
                            <img className="mx-auto sm:h-auto h-full" src={d.img} alt="Mobile" />
                          </div>
                          <p className="md:text-xl text-[10px] font-normal leading-4 text-[#1514397a] md:min-h-32 h-25 overflow-y-auto">{t(d.description)}</p>
                        </div>
                      </div>
                    </div>
                  );
                })
              }
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export default HowDoesTabo;