import React from "react";

const PageTitle = (props) => {
  const {
    title1 = "",
    title2 = "",
    title3 = "",
    className = "",
    titleClass = "",
    component = "h2",
  } = props;
  const title = (
    <>
      {title1}
      {title2 ? <span className="text-primary"> {title2}</span> : null}
      {title3 ? <span> {title3}</span> : null}
    </>
  );
  return (
    <div className={`mb-5 font-poppins ${className}`}>
      {component === "h1" ? (
        <h1
          className={`md:text-[52px] text-xl font-medium md:leading-relaxed leading-7 text-center lg:mt-16 md:mt-3 ${titleClass}`}
        >
          {title}
        </h1>
      ) : (
        <h2
          className={`md:text-[52px] text-xl font-medium md:leading-relaxed leading-7 text-center lg:mt-16 md:mt-3 ${titleClass}`}
        >
          {title}
        </h2>
      )}
    </div>
  );
};

export default PageTitle;
