import axios from "axios";
// axios.defaults.baseURL = "https://api.tabo.io/tabo/api";
axios.defaults.baseURL = process.env.REACT_APP_API_BASE;
const httpService = {
    get: (url) => {
        return axios
            .get(url)
    },

    post: (url, data) => {
        return axios
            .post(url, data)
    }
}

export default httpService