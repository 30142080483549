import './App.css';
import {
  Route,
  Routes,
  BrowserRouter,
} from "react-router-dom";

import Home from './components/home';
import Reservation from './components/reservation';
import Redirect from './components/redirectToLang/Redirect';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:lang" element={<Home />} />
        <Route path="/" element={<Redirect />} />
        <Route path="/reservation/:id" element={<Reservation />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
