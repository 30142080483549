import React from "react";
import Header from "../common/header";
import MainBanner from "./mainBanner";
import WhatIsTabo from "./whatIsTabo";
import WhyTabo from "./whyTabo";
import OurServices from "./ourServices";
import OurApp from "./ourApp";
import ContactForm from "./contactForm";
import PricingPlan from "./pricingPlan";
import Footer from "../common/footer";
import HowDoesTabo from "./howDoesTabo";
import Reinvented from "./reinvented";
import NewWhyTabo from "./NewWhyTabo";
import TrustedBy from "./TrustedBy";
import Tutorials from "./Tutorials/Tutorials";

const Home = () => {
  return (
    <div id="wrapperContentScroll">
      <Header />
      <MainBanner />
      <WhatIsTabo />
      <NewWhyTabo />
      <WhyTabo />
      <OurServices />
      <OurApp />
      <Tutorials />
      <Reinvented />
      <HowDoesTabo />
      <TrustedBy />
      <PricingPlan />
      <ContactForm />
      <Footer />
    </div>
  );
};

export default Home;