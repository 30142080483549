import React, { useEffect } from "react"



const Redirect = () => {
    useEffect(() => {
        var link = document.createElement('a');
        link.href = "/en";
        document.body.appendChild(link);
        link.click(); 
    },[])
    return (
        <div>
            
        </div>
    )
}


export default Redirect