import React, { useState } from "react";
import ContactFormBanner from '../../../assets/images/contact-banner.png';
import '../../../assets/common.css';
import { useTranslation, Trans } from "react-i18next";
import httpService from "../../../services/http";
import { ToastContainer, toast } from 'react-toastify'
import { CirclesWithBar } from "react-loader-spinner";

import PageTitle from "../../common/PageTitle";

const ContactForm = () => {
  const { t, i18n } = useTranslation();
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = () => {
    if (!email || !message) {
      const notify = () => toast(t("ContactForm.ValidationError"), { type: "error" });
      notify();
      return false
    }
    setLoading(true)
    httpService.post("/emails/", {
      "receiver": ["contact@tabo.io"],
      "templateId": "CONTACT_EMAIL",
      "dynamicTemplateData": {
        "subject": "Tabo.app email - landing page",
        "email": email,
        "message": message
      }
    }).then((response) => {
      setLoading(false)
      const notify = () => toast(t("ContactForm.successMessage"), { type: "success" });
      notify();
      setEmail("");
      setMessage("")
    }).catch(e => {
      setLoading(false)
      const notify = () => toast(t("ContactForm.serverError"), { type: "error" });
      notify();
    });
  }
  return (
    <>
      <ToastContainer />
      <div className="xl:w-full xl:max-w-[1440px] pb-10 mx-auto px-10" id="contact-us">
        <div className="xl:px-20 lg:px-10 px-1">
          <PageTitle title1={t("ContactForm.contactUs")} title2={t("ContactForm.contactUs1")} />
          <div className="flex justify-between flex-col md:flex-row">
            <div className="md:h-[225px] lg:block flex justify-center">
              <img src={ContactFormBanner} className="aspect-auto" alt="contact banner" />
            </div>
            <div className="mobile-view sm:mt-8 mt-5 block md:w-[60%] md:ml-6">
              <div className="message-box">
                <textarea type="text" placeholder={t("ContactForm.yourmsg")} className="w-full h-[200px] pt-3 pl-3 pb-4 border-2 border-solid border-[#EBEAED] rounded-md text-base" onChange={(e) => { setMessage(e.target.value) }} value={message} />
              </div>
              <div className="mt-4 flex justify-between">
                <input value={email} type="email" placeholder={t("ContactForm.email")} name="email" id="email" className="py-1 sm:py-3 pl-3 w-full border-2 border-solid border-[#EBEAED] rounded-full text-base" onChange={(e) => { setEmail(e.target.value) }} />
                <button type="button" className="text-white bg-[#6844F9] sm:px-11 px-6 py-3 ml-3 rounded-full w-[100px] sm:w-[200px] text-base" onClick={sendEmail}>
                  {loading ? (
                    <CirclesWithBar
                      type="Puff"
                      color="#00BFFF"
                      height={30}
                      width={30}
                      timeout={30000} //3 secs
                    />
                  ) : (
                    t("ContactForm.submit")
                  )
                  }</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactForm;