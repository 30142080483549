import React, { Fragment } from "react";

export default function Modal(props) {
  const {
    children,
    bodyClass = "",
    visible = false,
    headerTitle = "",
    showClose = false,
    toggle = () => { },
    titleCenter = false,
    containerClass = "",
    titleTextClass = "",
    modalContainerClass = "",
    renderFooter = () => null,
  } = props;
  if (!visible) return null;
  return (
    <Fragment>
      <div
        className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none font-poppins"
      >
        <div className={`relative w-auto my-3 mx-auto max-w-3xl ${modalContainerClass}`}>
          {/*content*/}
          <div className={`border-0 rounded-lg shadow-lg relative flex flex-col mx-auto bg-white outline-none focus:outline-none w-[100%] ${containerClass}`}>
            {
              headerTitle ? ( /*header*/
                <div className="flex items-start justify-between pl-6 pr-2 py-2 border-b border-solid border-slate-200 rounded-t">
                  {titleCenter ? <span className="w-8" /> : null}
                  <h3 className={`block text-xl font-semibold ${titleTextClass}`}>
                    {headerTitle}
                  </h3>
                  <button
                    onClick={toggle}
                    className="flex justify-end text-3xl leading-none font-semibold outline-none focus:outline-none w-8 hover:bg-transparent"
                  >
                    <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
              ) : showClose ? (
                <div className="flex items-start justify-end pl-6 pr-2 py-2 border-b border-solid border-slate-200 rounded-t">
                  <button
                    onClick={toggle}
                    className="flex justify-end text-3xl leading-none font-semibold outline-none focus:outline-none w-8 hover:bg-transparent"
                  >
                    <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none text-white">
                      ×
                    </span>
                  </button>
                </div>
              ) : null
            }
            {/*body*/}
            <div className={`relative p-6 flex-auto max-w-[90vw] max-h-[60vh] overflow-y-auto break-words ${bodyClass}`}>
              {children}
            </div>
            {/*footer*/}
            {
              renderFooter ? (
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  {renderFooter()}
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-hidden"></div>
    </Fragment>
  );
}