import React from "react";
import { useTranslation } from "react-i18next";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import PageTitle from "../../common/PageTitle";

import ReactSimplyCarouselExample from "./appSlider";
import ReactSimplyCarouselExample1 from "./appSlider/tab-client";

import 'react-tabs/style/react-tabs.css';
import '../../../assets/common.css';

const OurApp = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="xl:w-full xl:max-w-[1440px] mx-auto font-poppins pb-1 md:pb-40">
                <PageTitle title1={t("OurApp.title1")} title2={t("OurApp.title2")} component="h1"/>
                <div className="mx-auto font-poppins">
                    <div className="w-full flex justify-center md:mt-1">
                        <Tabs className="w-full react-tabs-ul">
                            <TabList className="flex justify-center mb-5 sm:mb-12 items-center px-4">
                                <Tab className="border w-full text-center first-tab py-3 flex items-center justify-center font-medium text-[14px] sm:text-base text-[#15143966] md:w-52 customFirstRounded h-[40px] sm:h-[50px]">{t("OurApp.tab1")}</Tab>
                                <Tab className="border w-full text-center md:w-52 py-3 flex items-center justify-center font-medium text-[14px] sm:text-base text-[#15143966] customLastRounded h-[40px] sm:h-[50px]">{t("OurApp.tab2")}</Tab>
                            </TabList>
                            <TabPanel>
                                <ReactSimplyCarouselExample />
                            </TabPanel>
                            <TabPanel>
                                <ReactSimplyCarouselExample1 />
                            </TabPanel>
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurApp;