import React, { useState } from "react";
import Choice from '../../../assets/images/choice.png';
import Mobility from '../../../assets/images/mobility.png';
import Target2 from '../../../assets/images/target-2.png';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation, Trans } from "react-i18next";
import PageTitle from "../../common/PageTitle";

const NewWhyTabo = () => {
  const { t, i18n } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
      centerMode: false,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,

    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
      centerMode: false,
      centerPadding: '60px',
    }
  };
  return (
    <>
      <div className="w-full md:max-w-[1190px] mx-auto px-0 pb-10 md:pb-40 bg-white sm:mt-15">
        <PageTitle title1={t("NewWhyTabo.title1")} title2={t("NewWhyTabo.title2")} component="h1"/>
        <div className="flex justify-center items-center sm:pb-2.5">
          <div className="sm:w-52 w-24 sm:pr-7 pr-1.5">
            <button className="px-3 sm:py-2.5 py-1.5 sm:border-2 border border-[#f3f4f5] rounded md:btn-dropShadow flex justify-center items-center w-full">
              <span className="sm:text-base text-[10px] font-light sm:leading-6 leading-4 text-black">{t("NewWhyTabo.subtitle1")}</span>
              <img className="ml-2 sm:block hidden" src={Choice} alt="Target" />
            </button>
          </div>
          <div className="sm:w-52 w-24 sm:pr-7 pr-1.5">
            <button className="px-3 sm:py-2.5 py-1.5 sm:border-2 border border-[#f3f4f5] rounded md:btn-dropShadow flex justify-center items-center w-full">
              <span className="sm:text-base text-[10px] font-light sm:leading-6 leading-4 text-black">{t("NewWhyTabo.subtitle2")}</span>
              <img className="ml-2 sm:block hidden" src={Target2} alt="Target" />
            </button>
          </div>
          <div className="sm:w-52 w-24 sm:pr-7 pr-1.5">
            <button className="px-3 sm:py-2.5 py-1.5 sm:border-2 border border-[#f3f4f5] rounded md:btn-dropShadow flex justify-center items-center w-full">
              <span className="sm:text-base text-[10px] font-light sm:leading-6 leading-4 text-black">{t("NewWhyTabo.subtitle3")}</span>
              <img className="ml-2 sm:block hidden" src={Mobility} alt="Location" />
            </button>
          </div>
        </div>
        <div className="hidden sm:block px-7">
          <div className="sm:mt-5 mt-2.5 md:px-7 px-3.5 md:py-5 py-2.5 sm:w-auto w-72 mx-auto sm:text-left text-center dece-card-boxShadow bg-[#f3f4f5]  rounded-lg">
            <p className="md:text-base text-xs font-light leading-7 text-secondary">{t("NewWhyTabo.para1")}</p>
          </div>
          <div className="sm:mt-5 mt-2.5 md:px-7 px-3.5 md:py-5 py-2.5 sm:w-auto w-72 mx-auto sm:text-left text-center dece-card-boxShadow bg-[#f4efed] rounded-lg">
            <p className="md:text-base text-xs font-light leading-7 text-secondary">{t("NewWhyTabo.para2")}</p>
          </div>
          <div className="sm:mt-5 mt-2.5 md:px-7 px-3.5 md:py-5 py-2.5 sm:w-auto w-72 mx-auto sm:text-left text-center dece-card-boxShadow bg-[#f3e8f4] rounded-lg">
            <p className="md:text-base text-xs font-light leading-7 text-secondary">{t("NewWhyTabo.para3")}</p>
          </div>
        </div>

        <div className="block sm:hidden mt-1">
          <Carousel
            showDots
            infinite
            swipeable
            draggable
            partialVisible
            keyBoardControl
            autoPlay={false}
            autoPlaySpeed={1000}
            responsive={responsive}
            transitionDuration={500}
            customTransition="all .5"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            containerClass={`carousel-container pl-[31px] md:!pl-0 mt-3`}
          >
            <div className="app-slider-item3 max-w-[97%]">
              <div className="h-[200px] dece-card-boxShadow bg-[#DCBADB] rounded-lg">
                <div className=" md:px-7 px-3.5 md:py-5 py-2.5 mx-auto sm:text-left text-center">
                  <p className="md:text-2xl font-light md:leading-10 leading-5 text-secondary text-xs">{t("NewWhyTabo.para1")}</p>
                </div>
              </div>
            </div>
            <div className="app-slider-item3 max-w-[97%]">
              <div className="h-[200px] dece-card-boxShadow bg-[#ced1d7] rounded-lg">
                <div className=" md:px-7 px-3.5 md:py-5 py-2.5 mx-auto sm:text-left text-center">
                  <p className="md:text-2xl font-light md:leading-10 leading-5 text-secondary text-xs">{t("NewWhyTabo.para2")}</p>
                </div>
              </div>
            </div>
            <div className="app-slider-item3 max-w-[97%]">
              <div className="h-[200px] dece-card-boxShadow bg-[#D9CAC3] rounded-lg">
                <div className=" md:px-7 px-3.5 md:py-5 py-2.5 mx-auto sm:text-left text-center">
                  <p className="md:text-2xl font-light md:leading-10 leading-5 text-secondary text-xs">{t("NewWhyTabo.para3")}</p>
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>

    </>
  )
}

export default NewWhyTabo;