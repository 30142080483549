import React, { useEffect } from "react";
import { useState } from 'react';
import Screencapture from '../../../assets/images/screencapture-dev-tabo.png';
import PhoneWhiteBg from '../../../assets/images/phone-white-bg.png';
import Carousel from 'react-multi-carousel';
import { useTranslation, Trans } from "react-i18next";

import PageTitle from "../../common/PageTitle";

const Reinvented = () => {
  const { t, i18n } = useTranslation();
  const images = [
    { img: Screencapture, alt: "Scrren 1" },
    { img: PhoneWhiteBg, alt: "Scrren 2" }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      
    }, 5 * 1000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 22,
    }
  };

  return (
    <div className="w-full max-w-7xl mx-auto px-5 sm:px-40 md:pb-40 pb-10 bg-white font-poppins">
      <PageTitle title1={t("Reinvented.title1")} title2={t("Reinvented.title2")} />
     
      <div className="flex items-center border-[1px] border-red-500 rounded-[40px] relative overflow-hidden mb-3 break-words">
        <div className="left-2 top-0 h-[25px] w-[40px] rounded-[25px] flex my-2 items-center justify-center text-[20px] font-extrabold z-10 text-primary">
          1
        </div>
        <div className="w-full h-[40px] pt-2 pb-2 text-black text-[16px] pl-[10px] pr-2">
        {t("RegisterOn")}<a target={"_blank"} href="https://tabo.io/" className="text-primary hover:text-primary visited:text-primary" rel="noopener noreferrer">&nbsp;tabo.io</a>
        </div>
      </div>
      <div className="flex items-center border-[1px] border-primary rounded-[40px] relative overflow-hidden mb-5 break-words">
        <div className="left-2 top-0 h-[25px] w-[40px] rounded-[25px] flex my-2 items-center justify-center text-[20px] font-extrabold z-10 text-red-500">
          2
        </div>
        <div className="w-full h-[40px] pt-2 pb-2 text-black text-[16px] pl-[10px] pr-2">
        {t("WaitingForAppr")}
        </div>
      </div>
      <div className="flex items-center border-[1px] border-red-500 rounded-[40px] relative overflow-hidden mb-5 break-words">
        <div className="left-2 top-0 h-[45px] w-[40px] rounded-[25px] flex my-2 items-center justify-center text-[20px] font-extrabold z-10 text-primary">
          3
        </div> 
        <div className="w-full pt-2 pb-2 text-black text-[16px] pl-[10px] pr-2 break-words">
          {t("LoginAndConfig")}
          <div className="text-base mb-1 mt-2 ">
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("BusinessProf")}
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("ChoosePrice")}
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("PdfMenu")}
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("SetupParams")}
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("SetPrices")}
          </div>
          <div className="md:text-xs text-xs mb-1 mt-2 pl-[6px] pr-2">
          {t("AddEmployees")}
          </div>
        </div>
      </div>
      <div className="flex items-center border-[1px] border-primary rounded-[40px] relative overflow-hidden mb-5 break-words">
        <div className="left-2 top-0 h-[45px] w-[40px] rounded-[25px] flex my-2 items-center justify-center text-[18px] font-extrabold z-10 text-red-500">
          4
        </div>
        <div className="w-full pt-2 pb-2 text-black text-[16px] pl-[10px] pr-2">
        {t("ReadyToBePublish")}
        </div>
      </div>
      <div className="italic text-xs text-red-500">
        <span className="text-xs text-black font-semibold">{t("Note")}</span>{t("Note1")}<br />
        {t("Note2")}
      </div>
      {/* <PageTitle title1={t("Reinvented.title1")} title2={t("Reinvented.title2")} />
      <div className="max-w-5xl w-full mx-auto sm:px-10">
        <div className="px-5">
          <div className="relative w-full lg:h-[475px] md:h-96 sm:h-72 print-tabo-banner">
            <img className="absolute w-full" src={Mac} alt="Macbook" />
            <img className="absolute left-0 right-0 mx-auto lg:top-7 md:top-5 sm:top-4 top-2 w-[78.66%]" src={Screencapture} alt="Macbook" />
            <div className="absolute -right-8 -bottom-5 h-[341px] w-[185px] md:block hidden">
              <div className="relative">
                <img className="w-[148px] absolute left-px right-0 top-3 mx-auto" src={PhoneWhiteBg} />
                <div className="absolute w-[141px] mx-auto px-0.5 left-0 right-0 top-0">
                  <img className="absolute top-7 h-36 w-full mx-auto" src={MobScreen} />
                  <img className="absolute h-40 mx-auto top-44" src={QR} />
                </div>
                <img className="absolute w-[185px] left-0 right-0 top-0" src={Iphone} alt="Iphone" />
              </div>
            </div>
          </div>
        </div>
        <div className="sm:mt-20 font-DMSans sm:block hidden">
          <div className="md:flex mb-[50px]">
            <div className="md:w-6/12 w-full mt-6 md:pr-5">
              <div>
                <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">01</h4>
                <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text1")}</p>
              </div>
            </div>
            <div className="md:w-6/12 w-full mt-6 md:pl-5">
              <div>
                <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">02</h4>
                <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text2")}</p>
              </div>
            </div>
          </div>
          <div className="md:flex mb-[50px]">
            <div className="md:w-6/12 w-full mt-6 md:pr-5">
              <div>
                <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">03</h4>
                <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text3")}</p>
              </div>
            </div>
            <div className="md:w-6/12 w-full mt-6 md:pl-5">
              <div>
                <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">04</h4>
                <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text4")}</p>
              </div>
            </div>
          </div>
          <div className="md:flex">
            <div className="md:w-6/12 w-full mt-6 md:pr-5">
              <div>
                <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">05</h4>
                <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text5")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='services-carousel why-tabo prin-tabo-container block sm:hidden mt-5'>
          <Carousel
            partialVisible={false} responsive={responsive}
            swipeable={true}
            draggable={true}
            showDots={true}
            infinite={true}
            autoPlay={false}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            className="carousel-width"
            dotListClass="custom-dot-list-style"
            itemClass="slider-paddingAppReinvented"
            containerClass="slider-paddingConatiner"
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            <div className="w-full h-[130px] rounded-lg ">
              <div className="sm:mt-5 mt-2.5 md:py-5 pt-2.5 sm:w-auto w-72 mx-auto text-left">
                <div>
                  <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">01</h4>
                  <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text1")}</p>
                </div>
              </div>
            </div>

            <div className="w-full h-[130px] rounded-lg">
              <div className="sm:mt-5 mt-2.5 md:py-5 pt-2.5 sm:w-auto w-72 mx-auto text-left">
                <div>
                  <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">02</h4>
                  <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text2")}</p>
                </div>
              </div>
            </div>

            <div className="w-full h-[130px] rounded-lg">
              <div className="sm:mt-5 mt-2.5 md:py-5 pt-2.5 sm:w-auto w-72 mx-auto text-left">
                <div>
                  <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">03</h4>
                  <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text3")}</p>
                </div>
              </div>
            </div>

            <div className="w-full h-[130px] rounded-lg">
              <div className="sm:mt-5 mt-2.5 md:py-5 pt-2.5 sm:w-auto w-72 mx-auto text-left">
                <div>
                  <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">04</h4>
                  <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text4")}</p>
                </div>
              </div>
            </div>

            <div className="w-full h-[130px] rounded-lg">
              <div className="sm:mt-5 mt-2.5 md:py-5 pt-2.5 sm:w-auto w-72 mx-auto text-left">
                <div>
                  <h4 className="md:text-3xl text-2xl font-medium leading-7 mb-2.5 text-[#1E0E62]">05</h4>
                  <p className="md:text-base text-xs font-normal md:leading-7 leading-4 text-[#151439cf]">{t("Reinvented.text5")}</p>
                </div>
              </div>
            </div>

          </Carousel>
        </div>
      </div> */}
    </div>
  )
}

export default Reinvented;