import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "booked": "Booked",
          "terms_condition": "Terms & Conditions",
          "submit_otp": "Please enter OTP receive on register mobile",
          "otp_placeholder": "Please enter OTP",
          "cancel": "Cancel",
          "resendOtp": "Resend OTP",
          "submit": "Submit",
          "warningAlert": "We strongly recommend you to not share this weblink with anyothers. They might use your reservation on for their own",
          "reservation_details": "Reservation Details",
          "qr_code": "QR code",
          "scan_text_warn": "Present the QR code upon arrival",
          "location": "Location",
          "address": "Address",
          "contact_info": "Contact info",
          "phone": "Phone",
          "website": "Website",
          "menu": "Menu",
          "our_menu": "see our menu",
          "cuisine": "Cuisine or food",
          "facilities": "Facilities",
          "agree_with": "Agree with",
          "check_out": "Check out",
          "reviews": "Reviews",
          "total_review": "Total Reviews",
          "release": "Release",
          "ocuupied": "Ocuupied",
          close: "Close",
          okay: "Okay",
          reservation_id: "Reservation ID",
          zone: "Zone",
          start_at: "Start At",
          end_at: "End At",
          not_allow_open: "Not allow to be opened in desktop, tablet & foldable phones",
          time: "Time",
          guests: "Guests",
          find_us: "How to find us",
          text_copy: "Text copied to clipboard!",
          select_language: "Select language",
          ambiance: "Ambiance",
          food: "Food",
          service: "Service",
          wifi: "Wifi",
          credit_card: "Credit Card",
          kids: "Kids",
          private_parking: "Private parking",
          public_parking: "Public parking",
          power_by: "Power by",
          read: "Read",
          month: "month",
          info1: "",
          info2: "",
          completed: "Completed",
          released: "Released",
          canceled: "Canceled",
          expired: "Expired",

          RegisterOn: "Register on",
          WaitingForAppr: "Wait for approval (max 30 min)",
          LoginAndConfig: "Login & Setup your business:",
          BusinessProf: "A. Business profile",
          ChoosePrice: "B. Choose your Subscription Pricing",
          PdfMenu: "C. Add a PDF menu (optional)",
          SetupParams: "D. Setup parameters",
          GridMap: "E. Build your map",
          SetPrices: "F. Setup prices (for Beaches and Pools only)",
          AddEmployees: "G. Add employees",
          ReadyToBePublish: "Your business is ready to be published",
          Note: "Note: ",
          Note1: "In a maximum of 30 minutes you will receive a notification that your business is approved or rejected.",
          Note2: "If your location is approved, you're ready to use our Business app and, based on your selected price plan, your location is visible to customers.",
          

          topHeader: {
            home: "Home",
            about: "About",
            trustedBy: "Trusted By",
            pricing: "Pricing",
            contactUs: "Contact us",
          },

          header: {
            home: "Home",
            about: "About tabo",
            pricing: "Pricing",
            contactUs: "Contact us",
            login: "Sign In",
            signUp: "Register"
          },

          mainBanner: {
            mainTitle: "Travel Access Book Organize",
            title: "TABO",
            para1: "Tabo is your real deputy manager",
            para2: "TABO helps you reserve sunbeds, beds or tables, having the appropriate information about a place, whether it's a beach, pool, restaurant terrace or club.",
            socialLabel1: "For The Clients:",
            socialLabel2: "For The Business:",
            xxx: "xxxxxxxxxxxxxxxxxxxxxxxx",
            up100: "Grow your business 100%",
            happyClient: "+1.1K users",
            a49: "5.0",
            k15Reviews: "",
            kReservations: "(+18K reservations)"
          },

          WhatIsTabo: {
            title1: "WHAT IS",
            title2: "TABO?",
            para1: "TABO is the one Market Place that offers everyone access to reservation and rental services in the hospitality services industry, focused on the beach, restaurant, swimming pools, clubs.",
            para2: "TABO Client is the application for users who want to find out details about locations such as photo gallery, work schedule, facilities, GPS directions, menu, prices, etc.  with the possibility of reserving sunbeds or tables.",
            para3: "TABO Business is the application designated for managing your own reservations and / or those received from Tabo Client clients.",
          },

          TrustedBy: {
            title1: "Trusted",
            title2: "By",
          },

          NewWhyTabo: {
            title1: "WHY",
            title2: "TABO?",
            subtitle1: "Flexibility",
            subtitle2: "Profesionalism",
            subtitle3: "Mobility",
            para1: "Using the TABO Business application, you will be able to manage online reservations, but also those made by phone or in-house. Often, not just one employee handles reservations on location, which makes reservations overlap and creates conflicts",
            para2: "Last moment problems even before they begin. The TABO Business app has an advanced notification system, which allows its users to automatically notify each other when they create or set a reservation on the location grid.",
            para3: "Tabo also has a number of notifications to let employees know which bookings are about to start or end. In our app, you will find a number of other benefits that will help you save time and staff.",
          },

          Reinvented: {
            title1: "How it",
            title2: "works?",
            RegisterOn: "Register on",
            WaitingForAppr: "Wait for approval (max 30 min)",
            LoginAndConfig: "Login and configurate your business:",
            BusinessProf: "A. Business profile",
            ChoosePrice: "B. Choose price plan",
            PdfMenu: "C. Add a pdf menu (optional)",
            SetupParams: "D. Setup different parmaeters",
            GridMap: "E. Build your map",
            SetPrices: "F. Set prices (for beach and pool)",
            AddEmployees: "G. Add employees",
            ReadyToBePublish: "Your business is ready to be published.",
            Note: "Note",
            Note1: "In a maximum of 30 minutes you will receive a notification that your business is approved or rejected.",
            Note2: "If your location is approved, you're ready to use our Business app and, based on your selected price plan, your location is visible to customers.",
          },

          WhyTabo: {
            ourVision: "Our Vision",
            ourMission: "Mission",
            titlepara1: "MISSION",
            titlepara2: "VISION",
            titlepara3: "VALUES",
            para1: "Our mission is to help our partners to optimize and maximize their operations, so that the business becomes a real success with minimal effort.",
            para2: "We live in an increasingly technological and computerized world, with modern time and money management systems, and these advantages can make the difference in the results of a business.",
            para3: "We are defined by speed and efficiency. We want to create a flow that is as efficient as possible for our partners, in the shortest possible time. We focus on partnership and trust - a business relationship based on trust will be closer to success.",
          },

          OurServices: {
            title1: "Our",
            title2: "Services",
            img1: "Seat or tables booking",
            img2: "Beaches and pools",
            img3: "Restaurants and clubs",
            img4: "Useful business informations",
          },

          OurApp: {
            title1: "Our",
            title2: "Apps",
            tab1: "Tabo Business",
            tab2: "Tabo Client",
            appSlider: {
              tab1: "Beaches and Pools",
              tab2: "Restaurants & Clubs"
            }
          },


          HowDoesTabo: {
            title1: "How TABO helps you with,",
            title2: "clients",
            text1: "The TABO client application is available for free on Google Play and the App Store, for anyone with a mobile phone",
            text2: "We can assure you, that more and more people know about TABO and your business.",
            text3: "Tens of thousands of people in your geographical area or interested in your services. You can see in real time the status, cost and quality of the services you provide",
          },

          ContactForm: {
            contactUs: "Contact",
            contactUs1: "us",
            yourmsg: "Your message",
            email: "Email",
            submit: "Send",
            ValidationError: "Invalid email format or message is empty",
            successMessage: "Thank you for contacting us. One of our team members will contact you!",
            serverError: "Internal Error, please try again later!"
          },

          Footer: {
            rights: "© 2024 TABO. All rights reserved.",
            rights1: "All rights reserved.",
            home: "Home",
            about: "About Tabo",
            TrustedBy: "Trusted by",
            pricing: "Pricing",
            contactUs: "Contact us",
            login: "Sign In",
            signUp: "Register",
            socialLabel1: "For Client",
            socialLabel2: "For business"
          },

          Beach: {
            infoBeach1: "This package is intended for those types of beaches that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of beaches that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of beaches that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 10%",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Set the reservations received from customers on the map",
            SetNotifications: "Presetting notifications according to your needs",
            StoreReservations: "Create and store your own bookings",
            DayOrRange: "Bookings can be created for a day, period or individual days",
            ProtocolDiscount: "Create bookings with protocol and/or discount",
            ChangeSwitchReserv: "Option to move reservations to other positions",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            GetStarted: "Choose",
          },

          Pool: {
            infoBeach1: "This package is intended for those types of pools that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of pools that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of pools that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 10%",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Set the reservations received from customers on the map",
            SetNotifications: "Presetting notifications according to your needs",
            StoreReservations: "Create and store your own bookings",
            DayOrRange: "Bookings can be created for a day, period or individual days",
            ProtocolDiscount: "Create bookings with protocol and/or discount",
            ChangeSwitchReserv: "Option to move reservations to other positions",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            GetStarted: "Choose",
          },

          RestaurantPlan: {
            infoBeach1: "This package is intended for those types of restaurants that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of restaurants that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of restaurants that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 1€",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Setup the reservations received from customers",
            AddAgenda: "Adding your own customers (Booklet)",
            SetNotifications: "Presetting notifications according to your needs",
            ServerNotifications: "Notifications 15 minutes before the start of the booking",
            BirthdayNotifications: "Notifications 30/15 days before birthdays",
            ManualorBulkSMS: "Send SMS while create a booking or scheduled",
            StoreReservations: "Create and store your own bookings",
            ShowName: "Feature to display customer names over the tables ",
            NumberOfReservations: "Feature to display number of bookings for the whole day",
            ColitionBlinking: "Collision warning for bookings",
            ChangeSwitchReserv: "Option to move bookings to other tables or areas",
            LateEditTime: "Option 'Customer Late' or 'Edit Occupancy Time'",
            HistoryNotifications: "Notification history for the current day",
            HistoryActions: "History of actions taken by your colleagues",
            MonitorReservations: "Section monitoring bookings in real time",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            InfoSMS: "Note: For SMS it is necessary to purchase credit, payment is made with the card in advance. Below you have the price list for each country",
            GetStarted: "Choose",
            FreeTrial: "Free trial: 1 month",
            IncludedSMS: "Included SMSs for free trial: 10",
          },

          Club: {
            infoBeach1: "This package is intended for those types of clubs that want to use a management system and be listed in the Tabo Client application. This entails the venue's obligation to accept bookings received from customers. Exceptions are those situations clearly justified for their rejection.",
            infoBeach2: "This package is intended for those types of clubs that want to use a management system, but without exposure to the client application",
            infoBeach3: "This package is intended for those types of clubs that want to receive bookings from customers but do not want to manage their own bookings",
            GenerateQR: "Generate free of charge QR Code Menu",
            CoastPerReservation: "Cost per booking received: 1€",
            Visibility: "Location listing in the Tabo Client application",
            Accept: "Setup the reservations received from customers",
            AddAgenda: "Adding your own customers (Booklet)",
            SetNotifications: "Presetting notifications according to your needs",
            ServerNotifications: "Notifications 15 minutes before the start of the booking",
            BirthdayNotifications: "Notifications 30/15 days before birthdays",
            ManualorBulkSMS: "Send SMS while create a booking or scheduled",
            StoreReservations: "Create and store your own bookings",
            ShowName: "Feature to display customer names over the tables ",
            NumberOfReservations: "Feature to display number of bookings for the whole day",
            ColitionBlinking: "Collision warning for bookings",
            ChangeSwitchReserv: "Option to move bookings to other tables or areas",
            LateEditTime: "Option 'Customer Late' or 'Edit Occupancy Time'",
            HistoryNotifications: "Notification history for the current day",
            HistoryActions: "History of actions taken by your colleagues",
            MonitorReservations: "Section monitoring bookings in real time",
            Statistics: "Complex Statistics",
            Statistics2: "Simple Statistics",
            MaxUsers2: "Number of Tabo Management users: maximum 2",
            MaxUsers5: "Number of Tabo Management users: maximum 5",
            MaxUsersUnlim: "Number of Tabo Management users: Unlimited",
            InfoSMS: "Note: For SMS it is necessary to purchase credit, payment is made with the card in advance. Below you have the price list for each country",
            GetStarted: "Choose",
            FreeTrial: "Free trial: 1 month",
            IncludedSMS: "Included SMSs for free trial: 10",
          },

          PricingPlan: {
            Beach: "Beach",
            Pool: "Pool",
            Restaurant: "Restaurant",
            Club: "Club",
            Plan: "Plan",
            Costs: "Costs"
          },
          Tutorials: {
            title: "Tutorials",
            Load: "Load More",
            reset: "Reset"
          }
        }
      },
      ro: {
        translations: {
          "booked": "Rezervat",
          "terms_condition": "Termeni si conditii",
          "submit_otp": "Vă rugăm să introduceți OTP primire pe mobilul de înregistrare",
          "otp_placeholder": "Vă rugăm să introduceți OTP",
          "cancel": "Anulare",
          "resendOtp": "Retrimiteți OTP",
          "submit": "Trimite",
          "warningAlert": "Vă recomandăm insistent să nu partajați acest link web cu alții. S-ar putea să folosească rezervarea pe cont propriu",
          "reservation_details": "Detalii rezervare",
          "qr_code": "Cod QR",
          "scan_text_warn": "Prezentați codul QR la sosire",
          "location": "Locație",
          "address": "Abordare",
          "contact_info": "Informatii de contact",
          "phone": "Telefon",
          "website": "Site-ul web",
          "menu": "Meniul",
          "our_menu": "vezi meniul nostru",
          "cuisine": "Bucătărie sau mâncare",
          "facilities": "Facilităţi",
          "agree_with": "De acord cu",
          "check_out": "Verifică",
          "reviews": "Recenzii",
          "total_review": "Total recenzii",
          "release": "Eliberare",
          close: "Închide",
          okay: "bine",
          reservation_id: "ID rezervare",
          zone: "Zona",
          start_at: "De la",
          end_at: "Pana la",
          not_allow_open: "Nu se permite deschiderea pe desktop, tabletă și telefoane pliabile",
          time: "Timp",
          guests: "Oaspeți",
          find_us: "Cum ne găsești",
          text_copy: "Text copiat în clipboard!",
          select_language: "Selecteaza limba",
          ambiance: "Ambianță",
          food: "Alimente",
          service: "Serviciu",
          wifi: "Wifi",
          credit_card: "Credit card",
          kids: "Loc de Joacă",
          private_parking: "Parcare privată",
          public_parking: "Parcare publică",
          power_by: "Powered by",
          read: "Citește",
          month: "lună",
          info1: "",
          info2: "",
          completed: "Terminată",
          released: "Terminată",
          canceled: "Anulată",
          expired: "Expirată",

          RegisterOn: "Înregistrează-te pe",
          WaitingForAppr: "Asteapta aprobarea (max 30 min)",
          LoginAndConfig: "Loghează-te & Setează-ți locația:",
          BusinessProf: "A. Pagina de profil",
          ChoosePrice: "B. Alege planul tarifar",
          PdfMenu: "C. Adaugă un meniu PDF (opțional)",
          SetupParams: "D. Setează parametrii localtiei",
          GridMap: "E. Crează harta locației",
          SetPrices: "F. Setează prețurile (doar pentru Plaje și Piscine)",
          AddEmployees: "G. Adaugă angajați",
          ReadyToBePublish: "Locația ta este gata să fie publicată",
          Note: "Notă: ",
          Note1: "În maxim 30 de minute vei primii o notificare cu privire la acceptarea sau respingerea solicitiarii.",
          Note2: "Dacă locația este aprobată, ești gata să folosești aplicația Business și, în funcție de planul tarifar ales, locația va fi listată în aplicația de client.",
          
          


          topHeader: {
            home: "Acasă",
            about: "Despre",
            trustedBy: "Parteneri",
            pricing: "Prețuri",
            contactUs: "Contactaţi-ne",
          },

          header: {
            home: "Acasă",
            about: "DESPRE TABO",
            pricing: "Tarife",
            contactUs: "Contactaţi-ne",
            login: "Loghează-te",
            signUp: "Inregistrează-te"
          },

          mainBanner: {
            mainTitle: "Travel Access Book Organize",
            title: "TABO",
            para1: "Tabo este adevăratul tău director adjunct",
            para2: "TABO te ajută să rezervi șezlonguri, paturi sau mese, având informațiile corespunzătoare despre loc fie că este vorba de plajă, piscină, terasă restaurantului sau club.",
            socialLabel1: "Pentru Clienți",
            socialLabel2: "Pentru Afaceri:",
            up100: "Crește-ți afacerea 100%",
            happyClient: "+1.1K Users",
            a49: "5.0",
            k15Reviews: "",
            kReservations: "(+18k Rezervări)"
          },

          WhatIsTabo: {
            title1: "CE ESTE",
            title2: "TABO?",
            para1: "TABO este acel Market Place care oferă tuturor acces la servicii de rezervare și închiriere din industria serviciilor de ospitalitate, cu focus pe plaje, restaurante, piscine, cluburi",
            para2: "TABO client este aplicația destianta utilizatorilor care doresc să afle detalii despre locații cum ar fi fotografii, program de lucru, facilități, indicații GPS, meniu, prețuri, etc, cu posibilitatea de a rezerva șezlonguri sau o masă",
            para3: "TABO Business este aplicația destiantă locațiilor pentru a gestiona rezervările proprii și/sau cele primite de la clienții aplicației Tabo Client.",
          },

          TrustedBy: {
            title1: "Parteneri",
            title2: "",
          },

          NewWhyTabo: {
            title1: "DE CE",
            title2: "TABO?",
            subtitle1: "Flexibilitate",
            subtitle2: "Profesionalism",
            subtitle3: "Mobilitate",
            para1: "Folosind aplicația TABO Business, vei putea gestiona rezervările online, dar și cele realizate telefonic sau in-house. În dese rânduri, nu doar un singur angajat se ocupă de rezervările din locație, lucru care face că rezervările să se suprapună și să creeze conflicte",
            para2: "Probleme în ultimile momente de dinaintea începerii acestora. Aplicația TABO Business deține un sistem avansat de notificări, lucru ce permite ca utilizatorilor ei să se notificace automat reciproc atunci când crează sau setează o rezervare pe gridul locației",
            para3: "De asemenea, Tabo dispune de o serie de notificări pentru a aduce în vedere angajaților, ce rezervări urmează să înceapă sau să se termine. În aplicația noastră, vei găși o serie de alte beneficii care te  vor ajută  să economisești timp și personal.",
          },

          WhyTabo: {
            ourVision: "Viziunea",
            ourMission: "Misiunea noastră",
            titlepara1: "MISIUNE",
            titlepara2: "VIZIUNE",
            titlepara3: "VALORI",
            para1: "Misiunea noastră este de a ne ajuta partenerii să-și optimizeze și să-și maximizeze operațiunile, astfel încât business-ul să devină un real succes cu efort minim.",
            para2: "Trăim într-o lume tot mai tehnologizată și informatizată, cu sisteme moderne de gestionare a timpului și banilor, iar aceste avantaje pot face diferența în rezultatele unui business.",
            para3: "Viteza și eficiența ne definesc. Ne dorim să creăm un flux cât mai eficient pentru partenerii noștri, în cel mai scurt timp posibil. Punem accent pe parteneriat și încredere – o relație de business bazată pe încredere va fi mai aproape de succes.",
          },

          Reinvented: {
            title1: "Cum",
            title2: "functionează?",
            RegisterOn: "Register on",
            WaitingForAppr: "Wait for approval (max 30 min)",
            LoginAndConfig: "Login and configurate your business:",
            BusinessProf: "A. Business profile",
            ChoosePrice: "B. Choose price plan",
            PdfMenu: "C. Add a pdf menu (optional)",
            SetupParams: "D. Setup different parmaeters",
            GridMap: "E. Build your map",
            SetPrices: "F. Set prices (for beach and pool)",
            AddEmployees: "G. Add employees",
            ReadyToBePublish: "Your business is ready to be published.",
            Note: "Note: ",
            Note1: "In maximum 30 minutes u will get a notification that your business is approved or rejected.",
            Note2: "If your business is approved, you are ready to use our Business app and based on the price plan selected, your location is visible for clients.",
          },
  

          OurServices: {
            title1: "Serviciile",
            title2: "noastre",
            img1: "Rezervă locuri sau mese",
            img2: "Plaje și piscine",
            img3: "Restaurante și cluburi",
            img4: "Informații utile despre Locații"
          },

          OurApp: {
            title1: "Aplicațiile",
            title2: "noastre",
            tab1: "Tabo Business",
            tab2: "Tabo Client",
            appSlider: {
              tab1: "Plaje & Piscine",
              tab2: "Restaurante & Cluburi"
            }
          },


          HowDoesTabo: {
            title1: "Cum vă aduce Tabo,",
            title2: "clienți",
            text1: "Aplicația client TABO este disponibilă gratuit pe Google Play și App Store, pentru oricine are un telefon mobil.",
            text2: "Ne asigurăm că tot mai mulți oameni știu despre TABO și află despre afacerea ta.",
            text3: "Mii de persoane din zona dvs. geografică sau interesate de serviciile dvs. vad disponibilitatea locurilor, costul și calitatea serviciilor pe care le oferiţi.",
          },


          ContactForm: {
            contactUs: "Contactaţi",
            contactUs1: "-ne",
            yourmsg: "Mesajul Tău",
            email: "Email",
            submit: "Trimite",
            ValidationError: "Email invalid sau mesajul este gol",
            successMessage: "Mulțumim că ne-ai contactat. Un coleg vă va contacta în scurt time!",
            serverError: "Eroare internă, te rugăm să încerci mai târziu!"
          },

          Footer: {
            rights: "© 2024 TABO.Toate drepturile rezervate.",
            //rights1: "Toate drepturile rezervate",
            home: "Acasă",
            about: "DESPRE TABO",
            TrustedBy: "Parteneri",
            pricing: "Tarife",
            contactUs: "Contactaţi-ne",
            login: "Loghează-te",
            signUp: "Inregistrează-te",
            socialLabel1: "Pentru Clienți",
            socialLabel2: "Pentru Afaceri",
          },

          Beach: {
            infoBeach1: "Acest pachet este destinat acelor tipuri de piscine care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoBeach2: "Acest pachet este destinat acelor tipuri de piscine care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoBeach3: "Acest pachet este destinat acelor tipuri de piscine care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primita: 10%",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea pe hartă a rezervarilor primite de la clienți",
            SetNotifications: "Prestabilirea notificărilor in funcție de nevoi",
            StoreReservations: "Creați și stocați propriile rezervări",
            DayOrRange: "Rezervările se pot crea pe o zi, perioadă sau zile individuale",
            ProtocolDiscount: "Creare rezervări cu protocol și/sau discount",
            ChangeSwitchReserv: "Optiune mutare rezervări la alte poziții",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            GetStarted: "Alege",



          },

          Pool: {
            infoPool1: "Acest pachet este destinat acelor tipuri de plaje care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoPool2: "Acest pachet este destinat acelor tipuri de plaje care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoPool3: "Acest pachet este destinat acelor tipuri de plaje care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primita: 10%",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea pe hartă a rezervarilor primite de la clienți",
            SetNotifications: "Prestabilirea notificărilor in funcție de nevoi",
            StoreReservations: "Creați și stocați propriile rezervări",
            DayOrRange: "Rezervările se pot crea pe o zi, perioadă sau zile individuale",
            ProtocolDiscount: "Creare rezervări cu protocol și/sau discount",
            ChangeSwitchReserv: "Optiune mutare rezervări la alte poziții",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            GetStarted: "Alege",

          },

          RestaurantPlan: {
            infoRestaurant1: "Acest pachet este destinat acelor restaurante care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoRestaurant2: "Acest pachet este destinat acelor restaurante care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoRestaurant3: "Acest pachet este destinat acelor restaurante care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primită: 1€",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea la mese a rezervarilor primite de la clienți",
            AddAgenda: "Adăugarea propriilor clienți (Agenda)",
            SetNotifications: "Prestabilirea notificărilor in functie de nevoi",
            ServerNotifications: "Notificări cu 15min inainte de incepera rezervării",
            BirthdayNotifications: "Notificări cu 30/15 zile înainte zile de nastere",
            ManualorBulkSMS: "SMS-uri trimise la crearea rezervarii sau programate",
            StoreReservations: "Creați și stocați propriile rezervări",
            ShowName: "Funcție afișare generala a numelor clienților la mese ",
            NumberOfReservations: "Funcție afișare număr rezervări pentru toată ziua",
            ColitionBlinking: "Avertizor coliziune rezervări",
            ChangeSwitchReserv: "Optiune mutare rezervari la alte mese sau zone",
            LateEditTime: "Opțiune 'Clientul Întârzie' sau 'Editare timp ocupare'",
            HistoryNotifications: "Istoric notificări pentru ziua în curs",
            HistoryActions: "Istoric acțiuni întreprinse de colegii dumneavoastră",
            MonitorReservations: "Secțiune monitorizare rezervări in timp real",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            InfoSMS: "Notă: Pentru SMS-uri este necesar să achiziționați credit, plata se face cu cardul în prealabil. Mai jos aveți lista de prețuri pentru fiecare țară",
            GetStarted: "Alege",
            FreeTrial: "Testare gratuită: 1 lună",
            IncludedSMS: "SMS incluse pentru testare: 10",
          },

          Club: {
            infoClub1: "Acest pachet este destinat acelor cluburi care doresc sa folosească un sistem de management și sa fie listată și in aplicația Tabo Client. Acest lucru atrage după sine obligativitatea locației de a accepta rezervările primite de la clienți. Fac excepție acele situații clar justificate pentru respingerea lor.",
            infoClub2: "Acest pachet este destinat acelor cluburi care doresc să folosească un sistem de management, dar fără a avea expunere în aplicația de client",
            infoClub3: "Acest pachet este destinat acelor cluburi care doresc să primească rezervări de la clienți, dar care nu doresc să își gestioneze propriile rezervări",
            GenerateQR: "Meniu QR Code Gratuit",
            CoastPerReservation: "Cost per rezervare primită: 1€",
            Visibility: "Listarea locației in aplicația Tabo Client",
            Accept: "Setarea la mese a rezervarilor primite de la clienți",
            AddAgenda: "Adăugarea propriilor clienți (Agenda)",
            SetNotifications: "Prestabilirea notificărilor in functie de nevoi",
            ServerNotifications: "Notificări cu 15min inainte de incepera rezervării",
            BirthdayNotifications: "Notificări cu 30/15 zile înainte zile de nastere",
            ManualorBulkSMS: "SMS-uri trimise la crearea rezervarii sau programate",
            StoreReservations: "Creați și stocați propriile rezervări",
            ShowName: "Funcție afișare generala a numelor clienților la mese ",
            NumberOfReservations: "Funcție afișare număr rezervări pentru toată ziua",
            ColitionBlinking: "Avertizor coliziune rezervări",
            ChangeSwitchReserv: "Optiune mutare rezervari la alte mese sau zone",
            LateEditTime: "Opțiune 'Clientul Întârzie' sau 'Editare timp ocupare'",
            HistoryNotifications: "Istoric notificări pentru ziua în curs",
            HistoryActions: "Istoric acțiuni întreprinse de colegii dumneavoastră",
            MonitorReservations: "Secțiune monitorizare rezervări in timp real",
            Statistics: "Statistici Complexe",
            Statistics2: "Statistici Simple",
            MaxUsers2: "Număr utilizatori Tabo Management: maxim 2",
            MaxUsers5: "Număr utilizatori Tabo Management: maxim 5",
            MaxUsersUnlim: "Număr utilizatori Tabo Management: Nelimitat",
            InfoSMS: "Notă: Pentru SMS-uri este necesar să achiziționați credit, plata se face cu cardul în prealabil. Mai jos aveți lista de prețuri pentru fiecare țară",
            GetStarted: "Alege",
            FreeTrial: "Testare gratuită: 1 lună",
            IncludedSMS: "SMS incluse pentru testare: 10",
          },

          PricingPlan: {
            Beach: "Plajă",
            Pool: "Piscină",
            Restaurant: "Restaurant",
            Club: "Club",
            Plan: "Plan",
            Costs: "Costuri"
          },
          Tutorials: {
            title: "Tutoriale",
            Load: "Mai mult",
            reset: "Resetează"
          }
        }
      }
    },
    fallbackLng: "ro",
    lng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    // keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
